import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/Home/Home.css";
import { Card } from "../StaticComponents/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { STATISTIC } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { ContactsOutlined, ContainerOutlined, ExperimentOutlined, FileOutlined, FileTextOutlined, SolutionOutlined, TeamOutlined } from "@ant-design/icons";
export function Home() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [isloader, setIsloader] = useState(false);
  const [statistic, setStatistic] = useState({});
  if (location.pathname == "/home" || location.pathname == "/") {
    location.pathname = "خانه";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadStatistic();
  }, []);
  const loadStatistic = async () => {
    try {
      setIsloader(true);
      const res = await client.query({
        query: STATISTIC,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res.data.getStatistic) {
        setStatistic(res.data.getStatistic);
        setIsloader(false);
      }
    } catch (error) {
      console.log(error);
      setIsloader(false);
    }
  };
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        <div className="card-container">
          <div className="row">
            <div className="card-parent" onClick={() => navigate("/drugs")}>
              <Card
                cardTitle="دارو ها"
                cardLoader={isloader}
                cardCount={statistic.drugCount}
                cardIcon={<ExperimentOutlined className="card-icon" />}
                onClick={() => navigate("/drugs")}
              />
            </div>
            <div
              className="card-parent"
              onClick={() => navigate("/factorList")}
            >
              <Card
                cardTitle="فاکتور ها"
                cardLoader={isloader}
                cardCount={statistic.factorCount}
                cardIcon={<FileTextOutlined className="card-icon" />}
                onClick={() => navigate("/factorList")}
              />
            </div>
            <div className="card-parent" onClick={() => navigate("/customers")}>
              <Card
                cardTitle="مشتریان"
                cardLoader={isloader}
                cardCount={statistic.customerCount}
                cardIcon={<SolutionOutlined className="card-icon" />}
                onClick={() => navigate("/customers")}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="card-parent"
              onClick={() => navigate("/checks")}
            >
              <Card
                cardTitle="اسناد"
                cardLoader={isloader}
                cardCount={statistic.checkCount}
                cardIcon={<FileOutlined className="card-icon" />}
                onClick={() => navigate("/checks")}
              />
            </div>
            <div className="card-parent" onClick={() => navigate("/stacks")}>
              <Card
                cardTitle="گدام ها"
                cardLoader={isloader}
                cardCount={statistic.stackCount}
                cardIcon={<ContainerOutlined className="card-icon" />}
                onClick={() => navigate("/stacks")}
              />
            </div>
            <div className="card-parent" onClick={() => navigate("/users")}>
              <Card
                cardTitle="کاربران"
                cardLoader={isloader}
                cardCount={statistic.userCount}
                cardIcon={<TeamOutlined className="card-icon" />}
                onClick={() => navigate("/users")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
