import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { CHECKS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
export function PrintChecks() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [checks, setChecks] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadChecks();
  }, []);
  const loadChecks = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CHECKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setIsLoader(false);
        setChecks([...res.data.getChecks]);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="printDrug-container">
        <PrintHeader type="لیست اسناد" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>شماره سند</th>
                <th>نوعیت</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {checks.map((check, index) => {
                return (
                  <tr id={check.id} key={check.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{check.customer.fullName}</td>
                    <td>{check.checkInNumber || check.checkOutNumber}</td>
                    <td>
                      <span>
                        {check.checkType == "Check_In"
                          ? "سند دریافتی"
                          : "سند پرداختی"}
                      </span>
                    </td>
                    <td>{check.amount}</td>
                    <td>{check.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
