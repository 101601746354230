import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { ROZNAMCHA } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import { Tag} from "antd";
export function PrintRoznamcha() {
  const location = useLocation()
  const client = useApolloClient();
  const navigate = useNavigate();
  const [roznamchas, setRoznamchas] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.day || {};
  console.log(receivedData);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    allRoznamcha();
  }, []);
  const allRoznamcha = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: ROZNAMCHA,
        variables: {
          date: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setRoznamchas([...res.data.getRoznamcha]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <div className="conatiner">
      <div className="file-container">
        {isLoader && <Loader />}
        <PrintHeader type="روزنامچه" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>شماره سند / فاکتور</th>
                <th>نوعیت سند / فاکتور</th>
                <th>طرف حساب</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {roznamchas.map((roz, index) => {
                return (
                  <tr id={roz.id} key={roz.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{roz.bellNumber}</td>
                    <td>
                    <Tag
          color={
            roz.bellType === "Sell" || roz.bellType === "Check_In"
              ? "success"
              : "error"
          }
          bordered={false}
          style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >
          {roz.bellType === "Check_In"
            ? "سند دریافتی"
            : roz.bellType === "Check_Out"
            ? "سند پرداختی"
            : roz.bellType === "Buy"
            ? "فاکتور خرید"
            : roz.bellType === "Sell"
            ? "فاکتور فروش"
            : roz.bellType === "salary"
            ? "معاش"
            : roz.bellType === "consume"
            ? "مصارف"
            : ""}
        </Tag>
                    </td>
                    <td>{roz.customer?.fullName || roz.employee?.fullName || roz.user?.userName}</td>
                    <td>{roz.amount}</td>
                    <td>{roz.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div>
    </div>
  );
}
