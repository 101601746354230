import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function DrugReportPrint() {
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [drugs, setDrugs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.selectedDrugReport || {};
  const [drugReportData, setDrugReportData] = useState(receivedData);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست دارو ها" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نوعیت</th>
                <th>نام</th>
                <th>کمپنی</th>
                <th>تعداد</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {drugReportData.map((drug, index) => {
                return (
                  <tr key={index + 1} className="table-row">
                    <td>{index + 1}</td>
                    <td>{drug.drugType.title}</td>
                    <td>{drug.name}</td>
                    <td>{drug.company}</td>
                    <td className={drug.amount < 0 ? "minesAmount" : ""}>{drug.amount}</td>
                    <td>{drug.expDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
