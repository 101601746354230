import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS, SALARIES } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function SelectedEmployeePrint() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [salaries, setSalaries] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);

  const receivedData = location.state?.employeeId || {};
  if (location.pathname == "/selectedEmployee") {
    location.pathname = "لیست معاشات";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadEmployeeSalaries();
  }, [referesh]);
  async function loadEmployeeSalaries() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: SALARIES,
        variables: {
          employeeId: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setSalaries([...res.data.getSalaries]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست معاشات" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>شماره</th>
                <th>نام</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
                <th>توضیحات</th>
              </tr>
            </thead>
            <tbody>
              {salaries.map((salary, index) => {
                return (
                  <tr key={index} className="table-row">
                    <td>{index + 1}</td>
                    <td>{salary.salaryNumber}</td>
                    <td>{salary.employeeId.fullName}</td>
                    <td>{salary.amount}</td>
                    <td>{salary.date}</td>
                    <td>{salary.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
