import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/Home/Home.css";
import { Card } from "../StaticComponents/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../../Styles/StaticComponents/AboutUs.css";
export function AboutUs() {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname == "/aboutUs") {
    location.pathname = "درباره ما";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
  });
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        <div className="profile-container">
          <div className="profile-content">
            <h1>معرفی تیک لند (TechLand) </h1>
            <img src="techLand.png" alt="techLand" className="team-logo" />
            <p>
              دنیا سراسر شگفتی هست و ما با تفاوتی که ایجاد میکنیم میتوانیم به
              این شگفتی ها بیفزاییم ! تیک لند (TechLand) اینک با تیمی فوق العاده
              خبره با انبوهی از تجربه در طراحی و مدیریت ویبسایت، اپلکشن موبایل
              (اندروید و آیفون)، دیتابس و سیستم های مدیریتی قدم به خلق سیستم های
              متفاوت گذاشته و میتواند مطلوب ترین گزینه برای تعریف متفاوت شما در
              بازار کار و مدیریت باشد. شما با ما میتوانید نوعی دیگر معرفی شوید و
              همیشه بروز باقی بمانید و ما از این لحاظ اطمنیان داریم که میتوانیم
              اطلاعات شما را به نوعی خاص متناسب با تکنولوژی های جدید مدیریت
              نماییم.
            </p>
          </div>
          <div className="profile">
            <img src="yousuf.jpeg" alt="yousuf" className="profile-image" />
            <div className="profile-details">
              <h1>محمد یوسف محمدی</h1>
              <span>برنامه نویس</span>
              <div className="profile-row">
                <img src="phone.png" alt="mobile" className="profile-icon" />
                <span>0790883362</span>
              </div>
              <div className="profile-row">
                <img src="gmail.png" alt="mobile" className="profile-icon" />
                <span>yousufmohammadi3222@gmail.com</span>
              </div>
              <div className="profile-row">
                <img src="website.png" alt="mobile" className="profile-icon" />
                <a
                  href="https://yousufmohammadi.github.io/profile/"
                  target="_blank"
                  className="website-link"
                >
                  دیدن ویبسایت
                </a>
              </div>
              <div className="profile-row">
                <a href="https://t.me/Mh_Yousuf" target="_blank">
                  <img
                    src="telegram.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="youtube.com/@protips1157" target="_blank">
                  <img
                    src="youtube.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a
                  href="https://www.facebook.com/yousuf.mohammadi.71"
                  target="_blank"
                >
                  <img
                    src="facebook.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="https://wa.me/93790883362" target="_blank">
                  <img
                    src="whatsapp.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="profile">
            <img src="reza.jpg" alt="yousuf" className="profile-image" />
            <div className="profile-details">
              <h1>محمد رضا حبیبی</h1>
              <span>برنامه نویس</span>
              <div className="profile-row">
                <img src="phone.png" alt="mobile" className="profile-icon" />
                <span>0796146514</span>
              </div>
              <div className="profile-row">
                <img src="gmail.png" alt="mobile" className="profile-icon" />
                <span>dev.rezahabibi@gmail.com</span>
              </div>
              <div className="profile-row">
                <img src="website.png" alt="mobile" className="profile-icon" />
                <a
                  href="https://github.com/rezahabibi786"
                  target="_blank"
                  className="website-link"
                >
                  دیدن ویبسایت
                </a>
              </div>
              <div className="profile-row">
                <a href="https://t.me/RezaHabibi786" target="_blank">
                  <img
                    src="telegram.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="youtube.com/@protips1157" target="_blank">
                  <img
                    src="youtube.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a
                  href="https://www.facebook.com/yousuf.mohammadi.71"
                  target="_blank"
                >
                  <img
                    src="facebook.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="https://wa.me/93796146514" target="_blank">
                  <img
                    src="whatsapp.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
