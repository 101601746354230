import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../StaticComponents/Alert";
import { useApolloClient } from "@apollo/client";
import { DRUGS, DRUGTYPES, STACKS } from "../../GraphQL/Query";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Popconfirm
} from "antd";
import {
  PlusOutlined,
  CaretDownOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import {
  ADDDRUG,
  DELETEDRUG,
  UPDATEDRUG,
} from "../../GraphQL/Mutation";
import moment from "moment";
const { Option } = Select;
export function Drugs() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [filteredDrugs, setFilteredDrugs] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [stacks, setStacks] = useState([]);
  const [drugTypes, setDrugTypes] = useState([]);
  const [searching, setSearching] = useState("");
  const [showDark, setShowDark] = useState(false);
  const [addDrugs, setAddDrug] = useState(false);
  const [editDrug, setEditDrug] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [selectedStack, setSelectedStack] = useState("");
  const [selectedDrugType, setSelectedDrugType] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  const [open, setOpen] = useState(false);
  if (location.pathname == "/drugs") {
    location.pathname = "داروها";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadDrugs();
    loadDrugTypes();
    loadStacks();
  }, [referesh, alert]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نوعیت",
      dataIndex: "drugType",
      key: "drugType",
      render: (drugType) => drugType.title,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "name",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.name)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      },
      key: "name",
      align: "center"
    },
    {
      title: "کمپنی",
      dataIndex: "company",
      key: "company",
      align: "center"
    },
    {
      title: "کشور",
      dataIndex: "country",
      key: "country",
      align: "center"
    },
    {
      title: "تعداد",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Tag
          bordered={false}
          color={amount > 0 ? "success" : amount === 0 ? "" : "error"}
          style={{
            fontSize: 15,
            fontFamily: "B-NAZANIN",
            direction: "ltr"
          }}
        >
          {amount}
        </Tag>
      ),
      align: "center"
    },
    {
      title: "قیمت",
      dataIndex: "price",
      key: "price",
      render: (price) => <Tag bordered={false}
        style={{
          fontSize: 15,
          fontFamily: "B-NAZANIN",
        }}>{price}</Tag>,
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "expDate",
      key: "expDate",
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, drug) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EllipsisOutlined
                onClick={() =>
                  navigate("/selectedDrug", {
                    state: { drugId: drug._id },
                  })
                }
              />
              <EditOutlined onClick={() => handleEditDrug(drug._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDelete(drug._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>

            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  async function loadDrugs() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: DRUGS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setDrugs([...res.data.getDrugs]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  async function loadDrugTypes() {
    try {
      const res = await client.query({
        query: DRUGTYPES,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setDrugTypes([...res.data.getDrugTypes]);
    } catch (error) {
      console.log(error);
    }
  }
  async function loadStacks() {
    try {
      const res = await client.query({
        query: STACKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setStacks([...res.data.getStacks]);
    } catch (error) {
      console.log(error);
    }
  }
  async function addNewDrug(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDDRUG,
        variables: {
          name: e.target.drugName.value,
          drugType: selectedDrugType,
          stack: selectedStack,
          company: e.target.drugCompany.value,
          country: e.target.drugCountry.value,
          expDate: e.target.drugExpDate.value,
          amount: 0,
          price: 0,
        },
      });
      if (res) {
        setAddDrug(false);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        // setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      setAlert({
        action: "",
        alertStatus: "",
      });
      console.log(error);
    }
  }
  async function handleDelete(drugId) {
    try {
      const res = await client.mutate({
        mutation: DELETEDRUG,
        variables: {
          id: drugId,
        },
      });
      if (res.data.deleteDrug.message == "Drug has been deleted successfully") {
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
        setReferesh(!referesh);
      }
      if (
        res.data.deleteDrug.message ==
        "please delete related factor before delete drug "
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "warning",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditDrug(drugId) {
    setShowDark(!showDark);
    const filteredRows = drugs.filter((item) => item._id == drugId);
    setFilteredDrugs(filteredRows[0]);
    setSelectedDrugType(filteredRows[0]?.drugType?._id)
    setSelectedStack(filteredRows[0]?.stack?._id)
    setEditDrug(true);
    console.log(filteredRows[0]);
  }
  async function handleUpdateDrug(e) {
    e.preventDefault();
    const id = filteredDrugs._id;
    try {
      const res = await client.mutate({
        mutation: UPDATEDRUG,
        variables: {
          drugId: id,
          name: e.target.drugName.value,
          drugType: selectedDrugType,
          stack: selectedStack,
          company: e.target.drugCompany.value,
          country: e.target.drugCountry.value,
          expDate: e.target.drugExpDate.value,
          amount: filteredDrugs.amount,
          price: filteredDrugs.price,
        },
      });
      if (res) {
        setEditDrug(!editDrug);
        setSelectedDrugType("")
        setSelectedStack("")
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  const items = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          paddingBlock="0"
          onClick={() => {
            navigate("/printDrug", {
              state: { printDrugType: "existDrug" },
            });
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          لیست داروهای موجود
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          onClick={() => {
            navigate("/printDrug", {
              state: { printDrugType: "allDrugs" },
            });
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          لیست تمام داروها
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<PrinterOutlined />}
          onClick={() => {
            navigate("/printDrug", {
              state: { printDrugType: "prestListDrug" },
            });
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          پرست لیست
        </Button>
      ),
    },
  ];
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی دارو"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setAddDrug(!addDrugs);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت دارو
              </Button>
            }
            <Dropdown
              menu={{ items }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                چاپ داروها
              </Button>
            </Dropdown>
          </div>
          <div className="file-content">
            {addDrugs && (
              <Modal
                title="ثبت دارو"
                open={addDrugs}
                onCancel={() => {
                  setAddDrug(!addDrugs);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewDrug}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام دارو"
                      id="drugName"
                      name="drugName"
                      size="large"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت دارو"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedDrugType(value);
                      }}
                      size="large"
                    >
                      {drugTypes.map((drugType) => (
                        <Option
                          key={drugType._id}
                          value={drugType._id}
                          style={{ direction: "rtl" }}
                        >
                          {drugType.title}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="گدام داروها"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedStack(value);
                      }}
                      size="large"
                    >
                      {stacks.map((stack) => (
                        <Option
                          key={stack._id}
                          value={stack._id}
                          style={{ direction: "rtl" }}
                        >
                          {stack.name}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کمپنی دارو"
                      id="drugCompany"
                      name="drugCompany"
                      size="large"
                    />
                    <Input
                      placeholder="کشور دارو"
                      id="drugCountry"
                      name="drugCountry"
                      size="large"
                    />
                    <DatePicker
                      placeholder="تاریخ دارو"
                      placement="topRight"
                      id="drugExpDate"
                      name="drugExpDate"
                      size="large"
                      style={{ width: "100%" }}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت دارو
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {editDrug && (
              <Modal
                title="ویرایش دارو"
                open={editDrug}
                onCancel={() => {
                  setEditDrug(!editDrug);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateDrug}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام دارو"
                      id="drugName"
                      name="drugName"
                      size="large"
                      defaultValue={filteredDrugs?.name}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت دارو"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedDrugType(value);
                      }}
                      size="large"
                      defaultValue={filteredDrugs?.drugType?.title}
                    >
                      {drugTypes.map((drugType) => (
                        <Option
                          key={drugType._id}
                          value={drugType._id}
                          style={{ direction: "rtl" }}
                        >
                          {drugType.title}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="گدام داروها"
                      style={{ width: "100%", direction: "rtl" }}
                      name="stack"
                      onChange={(value) => {
                        setSelectedStack(value);
                      }}
                      size="large"
                      defaultValue={filteredDrugs?.stack?.name}
                    >
                      {stacks.map((stack) => (
                        <Option
                          key={stack._id}
                          value={stack._id}
                          style={{ direction: "rtl" }}
                        >
                          {stack.name}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کمپنی دارو"
                      id="drugCompany"
                      name="drugCompany"
                      size="large"
                      defaultValue={filteredDrugs?.company}
                    />
                    <Input
                      placeholder="کشور دارو"
                      id="drugCountry"
                      name="drugCountry"
                      size="large"
                      defaultValue={filteredDrugs?.country}
                    />
                    <DatePicker
                      placeholder="تاریخ دارو"
                      placement="topRight"
                      id="drugExpDate"
                      name="drugExpDate"
                      size="large"
                      style={{ width: "100%" }}
                      defaultValue={moment(filteredDrugs?.expDate, "YYYY-MM-DD")}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش دارو
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={drugs}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
