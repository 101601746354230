import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import { STACKS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";

export function PrintStack() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [stacks, setStacks] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadStacks();
  }, []);
  const loadStacks = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: STACKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setStacks([...res.data.getStacks]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="printDrug-container">
        <PrintHeader type="لیست گدام ها" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th className="stack-code">ردیف</th>
                <th className="stack-name">نام</th>
                <th className="stack-type">نوعیت</th>
                <th className="stack-address">آدرس</th>
              </tr>
            </thead>
            <tbody>
              {stacks.map((stack, index) => {
                return (
                  <tr id={stack.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{stack.name}</td>
                    <td>{stack.type}</td>
                    <td>{stack.address}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div>
    </div>
  );
}
