import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function PrintDrug() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state?.printDrugType || {};
  console.log("receivedData", receivedData);
  const [drugs, setDrugs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadDrugs();
  }, []);
  async function loadDrugs() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: DRUGS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setDrugs([...res.data.getDrugs]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست دارو ها" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نوعیت</th>
                <th>نام</th>
                <th>کمپنی</th>
                {receivedData === "existDrug" ||
                receivedData === "allDrugs" ||
                receivedData === "priceDrugs" ? (
                  <th>تعداد</th>
                ) : (
                  ""
                )}
                {receivedData === "existDrug" ||
                receivedData === "allDrugs" ||
                receivedData === "priceDrugs" ? (
                  <th>قیمت</th>
                ) : (
                  ""
                )}
                {receivedData === "existDrug" ||
                receivedData === "allDrugs" ||
                receivedData === "priceDrugs" ? (
                  <th>جمع کل</th>
                ) : (
                  ""
                )}
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {drugs.map((drug, index) => {
                if (receivedData === "existDrug" && drug.amount > 0) {
                  return (
                    <tr className="table-row">
                      <td>{index + 1}</td>
                      <td>{drug.drugType.title}</td>
                      <td>{drug.name}</td>
                      <td>{drug.company}</td>
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td className={drug.amount < 0 ? "minesAmount" : ""}>
                          {receivedData === "allDrugs"
                            ? drug.amount
                            : receivedData === "existDrug"
                            ? drug.amount
                            : receivedData === "priceDrugs"
                            ? drug.amount
                            : ""}
                        </td>
                      ) : (
                        ""
                      )}
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td>{receivedData === "allDrugs" ? drug.price : ""}</td>
                      ) : (
                        ""
                      )}
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td>
                          {receivedData === "allDrugs"
                            ? drug.amount * drug.price
                            : ""}
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{drug.expDate}</td>
                    </tr>
                  );
                }
                if (receivedData !== "existDrug") {
                  return (
                    <tr className="table-row">
                      <td>{index + 1}</td>
                      <td>{drug.drugType.title}</td>
                      <td>{drug.name}</td>
                      <td>{drug.company}</td>
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td className={drug.amount < 0 ? "minesAmount" : ""}>
                          {receivedData === "allDrugs"
                            ? drug.amount
                            : receivedData === "existDrug"
                            ? drug.amount
                            : receivedData === "priceDrugs"
                            ? drug.amount
                            : ""}
                        </td>
                      ) : (
                        ""
                      )}
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td>{receivedData === "allDrugs" ? drug.price : ""}</td>
                      ) : (
                        ""
                      )}
                      {receivedData === "existDrug" ||
                      receivedData === "allDrugs" ||
                      receivedData === "priceDrugs" ? (
                        <td>
                          {receivedData === "allDrugs"
                            ? drug.amount * drug.price
                            : ""}
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{drug.expDate}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
