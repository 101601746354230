import { Link } from "react-router-dom"
import "../../Styles/StaticComponents/Card.css"
import { Loading } from "./Loading"
import { DeleteFilled, TeamOutlined } from "@ant-design/icons"
export function Card(props) {
    const cardTitle = props.cardTitle
    const cardCount = props.cardCount
    const cardIcon = props.cardIcon
    const cardLoader = props.cardLoader
    return (
      <div className="card">
        <span className="card-title">{cardTitle}</span>
        {/* <img src={cardImg} alt="card-icon" className="card-image" /> */}
        <Link>{cardIcon}</Link>
        {cardLoader ? (
          <Loading />
        ) : (
          <span className="card-counter">{cardCount}</span>
        )}
      </div>
    );
}