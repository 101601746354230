import "../../Styles/StaticComponents/PrintHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLeftLong} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BACKEND,DOMAIN_NAME } from "../../Constant/const";
export function PrintHeader(props) {
  const [profile,setProfile] = useState("")
  useEffect(()=>{
    const activeProfile = window.localStorage.getItem("profile");
    setProfile(JSON.parse(activeProfile))
  },[])
  const navigate = useNavigate()
  const type = props.type;
  return (
    <div className="container">
      <div className="printHeader-container">
        <span className="type">{type}</span>
        <span className="name">{profile?.titleOne}</span>
        <img src={profile?.logo ? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.logo}` : "logo.png"} alt="logo" className="print-logo" />
        <FontAwesomeIcon icon={faLeftLong} className="printHeader-icon" style={{ fontSize: 17,cursor:"pointer",position:"absolute",left:15 }}  onClick={() => navigate(-1)}/>
      </div>
    </div>
  );
}
