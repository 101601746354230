import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function CustomerReportPrint() {
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [drugs, setDrugs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.selectedCustomerReport || {};
  const [customerReportData, setCustomerReportData] = useState(receivedData);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست مشتریان" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>کمپنی</th>
                <th>آدرس</th>
                <th>مویابل</th>
                <th>ولایت</th>
                <th>الباقی</th>
              </tr>
            </thead>
            <tbody>
              {customerReportData.map((customer, index) => {
                return (
                  <tr id={customer._id} key={customer._id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{customer.fullName}</td>
                    <td>{customer.company}</td>
                    <td>{customer.address}</td>
                    <td>{customer.phoneNumber}</td>
                    <td>{customer.city}</td>
                    <td>
                      {Math.abs(customer?.balance)}
                      <span>
                        {customer?.balance > 0
                          ? "  بستانکار  "
                          : customer?.balance < 0
                          ? "  بدهکار  "
                          : "  تسویه  "}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
