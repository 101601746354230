import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { REMITTANCE } from "../../GraphQL/Query";
import {
  ADDREMITTANCE,
  DELETEREMITTANCE,
  UPDATEREMITTANCE,
} from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import {
  Button,
  Dropdown,
  Input,
  Table,
  Space,
  Tag,
  InputNumber,
  Modal,
  Select,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  AlignRightOutlined,
  CaretDownOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
const { Option } = Select;
export function Remittance() {
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [remittances, setRemittances] = useState([]);
  const [filteredRemittance, setFilteredRemittance] = useState([]);
  const [searching, setSearching] = useState("");
  const [newRemittance, setNewRemittance] = useState(false);
  const [editRemittance, setEditRemittance] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/remittance") {
    location.pathname = "بانک و صرافی";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    allRemittance();
  }, [referesh]);
  function goToPrintRemittance() {
    navigate("/printRemittance");
  }
  const allRemittance = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: REMITTANCE,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setRemittances([...res.data.getRemittances]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  async function addNewRemittance(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDREMITTANCE,
        variables: {
          number: e.target.havalaNumber.value,
          type: selectedType,
          amount: Number(e.target.amount.value),
          customerName: e.target.customerName.value,
          via: e.target.via.value,
          shopAddress: e.target.shopAddress.value,
          date: e.target.inputDate.value,
          description: e.target.description.value,
        },
      });
      if (res) {
        setNewRemittance(!newRemittance);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeleteRemittance(remId) {
    try {
      setIsLoader(!isLoader);
      const res = await client.mutate({
        mutation: DELETEREMITTANCE,
        variables: {
          id: remId,
        },
      });
      if (res) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleEditRemittance(remId) {
    setShowDark(!showDark);
    setEditRemittance(!editRemittance);
    const filterRemittance = remittances.filter((rem) => rem._id == remId);
    setFilteredRemittance(filterRemittance[0]);
    setSelectedType(filterRemittance[0].type)
  }
  async function updateRemittance(e) {
    e.preventDefault();
    const id = filteredRemittance._id;
    try {
      const res = await client.mutate({
        mutation: UPDATEREMITTANCE,
        variables: {
          remittanceId: id,
          number: e.target.havalaNumber.value,
          type: selectedType,
          amount: Number(e.target.amount.value),
          customerName: e.target.customerName.value,
          via: e.target.via.value,
          shopAddress: e.target.shopAddress.value,
          date: e.target.inputDate.value,
          description: e.target.description.value,
        },
      });
      if (res) {
        setEditRemittance(!editRemittance);
        setShowDark(!showDark);
        setSelectedType("")
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  const items = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<AlignRightOutlined />}
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<AlignRightOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("Card_to_card");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          کارت به کارت
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<AlignRightOutlined />}
          onClick={() => {
            setSearching("Exchange");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          صرافی
        </Button>
      ),
    },
  ];
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "شماره کارت / حواله",
      dataIndex: "number",
      render: (number, rem) => rem.number,
      key: "number",
      filteredValue: [searching],
      onFilter: (value, rem) => {
        return String(rem.number)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
          ||
          String(rem.type).includes(value)
          ||
          String(rem.customerName).includes(value)
          ;
      },
      align: "center"
    },
    {
      title: "نوعیت حواله",
      dataIndex: "type",
      key: "type",
      render: (type, rem) => (
        <Tag bordered={false}
          style={{
            fontSize: 15,
            fontFamily: "B-NAZANIN",
          }}
        >
          {rem.type === "Card_to_card" ? "کارت به کارت" : "صرافی"}
        </Tag>
      ),
      align: "center"
    },
    {
      title: "طرف حساب",
      dataIndex: "customerName",
      key: "customerName",
      render: (customerName, rem) => rem.customerName,
      align: "center"
    },
    {
      title: "مبلغ",
      dataIndex: "amount",
      key: "amount",
      render: (amount, rem) => <Tag bordered={false}
        style={{
          fontSize: 15,
          fontFamily: "B-NAZANIN",
        }}
      >{rem.amount}</Tag>,
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "date",
      key: "date",
      render: (date, rem) => rem.date,
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, rem) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EditOutlined onClick={() => handleEditRemittance(rem?._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteRemittance(rem?._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی شماره (کارت ، حواله)"
              allowClear
              size="large"
              onSearch={(value) => {
                setSearching(value);
              }}
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            <Dropdown
              menu={{ items }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                انتخاب سند
              </Button>
            </Dropdown>
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewRemittance(!newRemittance);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                افزودن حواله
              </Button>
            }
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={goToPrintRemittance}
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {newRemittance && (
              <Modal
                title="ثبت حواله جدید"
                open={newRemittance}
                onCancel={() => {
                  setNewRemittance(!newRemittance);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewRemittance}>
                  <Space direction="vertical">
                    <Input
                      id="havalaNumber"
                      name="havalaNumber"
                      size="large"
                      placeholder="شماره کارت / حواله"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت حواله"
                      style={{ width: "100%", direction: "rtl" }}
                      name="type"
                      onChange={(value) => {
                        setSelectedType(value);
                      }}
                      size="large"
                    >
                      <Option
                        key={"Exchange"}
                        style={{ direction: "rtl" }}
                        value="Exchange"
                      >
                        صرافی
                      </Option>
                      <Option
                        key={"Card_to_card"}
                        style={{ direction: "rtl" }}
                        value="Card_to_card"
                      >
                        کارت به کارت
                      </Option>
                    </Select>
                    <Input
                      id="customerName"
                      name="customerName"
                      size="large"
                      placeholder="طرف حساب"
                    />
                    <Input
                      id="via"
                      name="via"
                      size="large"
                      placeholder="نام حواله کننده"
                    />
                    <InputNumber
                      id="amount"
                      name="amount"
                      size="large"
                      placeholder="مبلغ حواله"
                      min={0}
                      style={{ width: "100%" }}
                    />
                    <Input
                      id="shopAddress"
                      name="shopAddress"
                      size="large"
                      placeholder="آدرس حواله"
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Input
                      id="description"
                      name="description"
                      size="large"
                      placeholder="توضیحات حواله"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت حواله
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {editRemittance && (
              <Modal
                title="ویرایش حواله"
                open={editRemittance}
                onCancel={() => {
                  setEditRemittance(!editRemittance);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={updateRemittance}>
                  <Space direction="vertical">
                    <Input
                      id="havalaNumber"
                      name="havalaNumber"
                      size="large"
                      placeholder="شماره کارت / حواله"
                      defaultValue={filteredRemittance.number}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="نوعیت حواله"
                      style={{ width: "100%", direction: "rtl" }}
                      name="type"
                      onChange={(value) => {
                        setSelectedType(value);
                      }}
                      size="large"
                      defaultValue={filteredRemittance.type}
                    >
                      <Option
                        key={"Exchange"}
                        style={{ direction: "rtl" }}
                        value="Exchange"
                      >
                        صرافی
                      </Option>
                      <Option
                        key={"Card_to_card"}
                        style={{ direction: "rtl" }}
                        value="Card_to_card"
                      >
                        کارت به کارت
                      </Option>
                    </Select>
                    <Input
                      id="customerName"
                      name="customerName"
                      size="large"
                      placeholder="طرف حساب"
                      defaultValue={filteredRemittance.customerName}
                    />
                    <Input
                      id="via"
                      name="via"
                      size="large"
                      placeholder="نام حواله کننده"
                      defaultValue={filteredRemittance.via}
                    />
                    <InputNumber
                      id="amount"
                      name="amount"
                      size="large"
                      placeholder="مبلغ حواله"
                      min={0}
                      style={{ width: "100%" }}
                      defaultValue={filteredRemittance.amount}
                    />
                    <Input
                      id="shopAddress"
                      name="shopAddress"
                      size="large"
                      placeholder="آدرس حواله"
                      defaultValue={filteredRemittance.shopAddress}
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={filteredRemittance.date}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Input
                      id="description"
                      name="description"
                      size="large"
                      placeholder="توضیحات حواله"
                      defaultValue={filteredRemittance.description}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش حواله
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={remittances}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
