import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function SelectedDrugPrint() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [drugs, setDrugs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.selectedDrug || {};
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadDrugDetails();
  }, []);
  async function loadDrugDetails() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: DRUGDETAILS,
        variables: {
          id: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setDrugs([...res.data.getDrugDetails]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست دارو ها" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نوعیت</th>
                <th>نام</th>
                <th>کمپنی</th>
                <th>تعداد</th>
                <th>قیمت</th>
                <th>کل</th>
                <th>مشتری</th>
                <th>فاکتور</th>
                <th>پرداختی</th>
                <th>شماره</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {drugs.map((drug, index) => {
                return (
                  <tr key={index} className="table-row">
                    <td>{index + 1}</td>
                    <td>{drug?.items[0]?.drug?.drugType?.title}</td>
                    <td>{drug?.items[0]?.drug?.name}</td>
                    <td>{drug?.items[0]?.drug?.company}</td>
                    <td>{drug?.items[0]?.quantity}</td>
                    <td>{drug?.items[0]?.price}</td>
                    <td>{drug?.items[0]?.total}</td>
                    <td>{drug?.customer?.fullName}</td>
                    <td>
                      <span>
                        {drug?.factorType == "Buy" ? "خرید" : "فروش"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {drug?.paymentType == "Cash" ? "نقده" : "نسیه"}
                      </span>
                    </td>
                    <td>{drug?.buyFactorNumber || drug?.sellFactorNumber}</td>
                    <td>{drug?.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
