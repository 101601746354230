import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERDETAILS } from "../../GraphQL/Query";
import {
  Button,
  Dropdown,
  Input,
  Table,
  Menu,
  Tag,
  Space,
  Spin,
} from "antd";
import {
  CaretDownOutlined,
  PrinterOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRightLong} from "@fortawesome/free-solid-svg-icons";
export function SelectedCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [customers, setCustomers] = useState([]);
  const [searching, setSearching] = useState("");
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [balance, setBalance] = useState(0);
  const [page, setPage] = useState(1);
  const receivedData = location.state?.customerId || {};
  if (location.pathname == "/selectedCustomer") {
    location.pathname = "مشتری انتخاب شده";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomerDetails();
  }, [referesh, balance]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center",
    },
    {
      title: "نام",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName, customer) => customer?.customer?.fullName,
      filteredValue: [searching],
      onFilter: (value, customer) => {
        return String(customer?.customer?.fullName)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) 
          ||
          String(customer.factorType).includes(value)
          ||
          String(customer.checkType).includes(value)
          ||
          String(customer.paymentType).startsWith(value)
          ;
      },
      align: "center",
    },
    {
      title: "شماره بل",
      dataIndex: [
        "checkInNumber",
        "checkOutNumber",
        "sellFactorNumber",
        "buyFactorNumber",
      ],
      key: "billNumber",
      render: (billNumber, customer) => (
        <Tag bordered={false}>
          {customer?.checkInNumber ||
            customer?.checkOutNumber ||
            customer?.sellFactorNumber ||
            customer?.buyFactorNumber}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "نوعیت بل",
      dataIndex: ["factorType", "checkType"],
      key: "billType",
      render: (billType, customer) => (
        <Tag
          color={
            customer?.factorType === "Sell" ||
            customer?.checkType === "Check_In"
              ? "success"
              : "error"
          }
          bordered={false}
        >
          {customer?.factorType === "Buy"
            ? "خرید"
            : customer?.factorType === "Sell"
            ? "فروش"
            : customer?.checkType === "Check_In"
            ? "دریافتی"
            : customer?.checkType === "Check_Out"
            ? "پرداختی"
            : ""}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "نوعیت پرداختی",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType, customer) => (
        <Tag
          color={customer?.paymentType === "Cash" ? "success" : "error"}
          bordered={false}
        >
          {customer?.paymentType === "Cash" ? "نقده" : customer?.paymentType === "No_Cash" ? "نسیه" : ""}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "مبلغ",
      dataIndex: "amount",
      key: "amount",
      render: (amount, customer) => (
        <Tag
          bordered={false}
          style={{
            fontSize: 15,
            fontFamily: "B-NAZANIN",
          }}
        >
          {customer?.amount}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "بلانس",
      dataIndex: "balance",
      key: "balance",
      render: (balance,customer) => (
        <Tag
          bordered={false}
          color={customer?.customerBalance > 0 ? "error" : customer?.customerBalance === 0 ? "" : "success"}
          style={{
            fontSize: 15,
          }}
        >
          {
            <Space>
              {Math.abs(customer?.customerBalance)}
              {""}
              {customer?.customerBalance > 0 ? "بستانکار" : customer?.customerBalance === 0 ? "تسویه" : "بدهکار"}
            </Space>
          }
        </Tag>
      ),
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "date",
      key: "date",
      render: (date, customer) => customer?.date,
      align: "center",
    },
  ];
  async function loadCustomerDetails() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CUSTOMERDETAILS,
        variables: {
          id: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setCustomers([...res.data.getCustomerDetails]);
        setIsLoader(false);
      }
      setBalance(customers[0]?.customer?.balance);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  const filterTypes = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("No_Cash");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          نسیه
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Cash");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          نقده
        </Button>
      ),
    },
  ];
  const factorTypes = (
    <Menu>
      {filterTypes.map((filterType) => (
        <Menu.Item key={filterType.key}>{filterType.label}</Menu.Item>
      ))}
    </Menu>
  );
  const filterPayments = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("Buy");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          خرید
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Sell");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          فروش
        </Button>
      ),
    },
  ];
  const paymentTypes = (
    <Menu>
      {filterPayments.map((filterPayment) => (
        <Menu.Item key={filterPayment.key}>{filterPayment.label}</Menu.Item>
      ))}
    </Menu>
  );
  const filterChecks = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("Check_In");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          دریافتی
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Check_Out");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          پرداختی
        </Button>
      ),
    },
  ];
  const checkTypes = (
    <Menu>
      {filterChecks.map((filterCheck) => (
        <Menu.Item key={filterCheck.key}>{filterCheck.label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        <div className="file-container">
          <div className="file-header">
          <FontAwesomeIcon icon={faRightLong} style={{ fontSize: 17,cursor:"pointer" }}  onClick={() => navigate(-1)}/>
          <Input.Search
              placeholder="جستجوی مشتری"
              allowClear
              size="large"
              onSearch={(value) =>{
                setSearching(value);
              }}
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
                display:"none"
              }}
            />
            <Dropdown
              overlay={factorTypes}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                پرداختی
              </Button>
            </Dropdown>
            <Dropdown
              overlay={paymentTypes}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                فاکتور
              </Button>
            </Dropdown>
            <Dropdown
              overlay={checkTypes}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                سند
              </Button>
            </Dropdown>
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={() =>
                navigate("/customerSelectedPrint", {
                  state: { customerId: receivedData },
                })
              }
              className="btn"
            >
              چاپ
            </Button>
            {(balance == 0) || (balance > 0) || (balance < 0) ? (
              <Tag
                bordered={false}
                color={balance > 0 ? "error" : balance < 0 ? "success" : ""}
                style={{
                  fontSize: 20,
                  fontFamily: "B-NAZANIN",
                  height: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {
                  <Space>
                    {Math.abs(balance)}
                    {""}
                    {balance > 0
                      ? "بستانکار"
                      : balance === 0
                      ? "تسویه"
                      : "بدهکار"}
                  </Space>
                }
              </Tag>
            ) : (
              <Spin />
            )}
          </div>
          <div className="file-content">
            <Table
              dataSource={customers}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger:false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
