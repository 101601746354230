import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { USERS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
export function PrintUser() {
  const client = useApolloClient();
  const [users, setUsers] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadUsers();
  }, []);
  const loadUsers = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: USERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setUsers([...res.data.getUsers]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <div className="conatiner">
      <div className="file-container">
        {isLoader && <Loader />}
        <PrintHeader type="لیست کاربران" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>نام کاربری</th>
                <th>پسورد</th>
                <th>صلاحیت</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                return (
                  <tr key={index} className="table-row">
                    <td>{index + 1}</td>
                    <td>{user.firstName}</td>
                    <td>{userRole == "Admin" ? user.userName : "🔒"}</td>
                    <td>{userRole == "Admin" ? user.password : "🔒"}</td>
                    <td>{user.role}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div>
    </div>
  );
}
