import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Components/Home/Home";
import "./App.css";
import { Drugs } from "./Components/Drugs/Drugs";
import { PrintDrug } from "./Components/Drugs/PrintDrug";
import { BuyFactor } from "./Components/Factors/BuyFactor";
import { SaleFactor } from "./Components/Factors/SaleFactor";
import { Customers } from "./Components/Customers/Customers";
import { Stacks } from "./Components/Stacks/Stacks";
import { Users } from "./Components/Users/User";
import { Checks } from "./Components/Checks/Checks";
import { Roznamcha } from "./Components/Roznamcha/Roznamcha";
import { PrintFactor } from "./Components/Factors/PrintFactor";
import { PrintCustomer } from "./Components/Customers/PrintCustomer";
import { PrintStack } from "./Components/Stacks/PrintStack";
import { PrintChecks } from "./Components/Checks/PrintChecks";
import { PrintRoznamcha } from "./Components/Roznamcha/PrintRoznamcha";
import { PrintUser } from "./Components/Users/PrintUser";
import { PrintEmployee } from "./Components/Eployees/PrintEmployee";
import { Login } from "./Components/StaticComponents/Login";
import { AboutUs } from "./Components/StaticComponents/AboutUs";
import { DrugReports } from "./Components/Reports/DrugReports";
import { FactorReports } from "./Components/Reports/FactorReports";
import { CheckReport } from "./Components/Reports/CheckReports";
import { CustomerReports } from "./Components/Reports/CustomerReports";
import { Eployee } from "./Components/Eployees/Eployee";
import { Remittance } from "./Components/Remittance/Remittance";
import { PrintRemittance } from "./Components/Remittance/PrintRemittance";
import { FactorList } from "./Components/Factors/FactorList";
import { DrugReportPrint } from "./Components/Reports/DrugReportPrint";
import { FactorReportPrint } from "./Components/Reports/FactorReportPrint";
import { CheckReportPrint } from "./Components/Reports/CheckReportPrint";
import { CustomerReportPrint } from "./Components/Reports/CustomerReportPrint";
import { PrintCheck } from "./Components/Checks/PrintCheck";
import { SelectedDrug } from "./Components/Drugs/SelectedDrug";
import { SelectedDrugPrint } from "./Components/Drugs/SelectedDrugPrint";
import { SelectedEmployee } from "./Components/Eployees/SelectedEmployee";
import { SelectedEmployeePrint } from "./Components/Eployees/SelectedEmployeePrint";
import { Expenses } from "./Components/Expenses/Expenses";
import { SelectedCustomer } from "./Components/Customers/CustomerSelected";
import { PrintExpenses } from "./Components/Expenses/PrintExpenses";
import { CustomerSelectedPrint } from "./Components/Customers/CustomerSelectedPrint";
import { DrugsType } from "./Components/DrugsType/DrugsType";
import { Settings } from "./Components/Settings/Settings";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <Fragment>
              <Login />
            </Fragment>
          }
        />
        <Route
          path="/"
          element={
            <Fragment>
              <Home />
            </Fragment>
          }
        />
        <Route
          path="/home"
          element={
            <Fragment>
              <Home />
            </Fragment>
          }
        />
        <Route
          path="/settings"
          element={
            <Fragment>
              <Settings />
            </Fragment>
          }
        />
        <Route
          path="/drugsType"
          element={
            <Fragment>
              <DrugsType />
            </Fragment>
          }
        />
        <Route
          path="/drugs"
          element={
            <Fragment>
              <Drugs />
            </Fragment>
          }
        />
        <Route
          path="/buyFactor"
          element={
            <Fragment>
              <BuyFactor />
            </Fragment>
          }
        />
        <Route
          path="/saleFactor"
          element={
            <Fragment>
              <SaleFactor />
            </Fragment>
          }
        />
        <Route
          path="/customers"
          element={
            <Fragment>
              <Customers />
            </Fragment>
          }
        />
        <Route
          path="/stacks"
          element={
            <Fragment>
              <Stacks />
            </Fragment>
          }
        />
        <Route
          path="/users"
          element={
            <Fragment>
              <Users />
            </Fragment>
          }
        />
        <Route
          path="/checks"
          element={
            <Fragment>
              <Checks />
            </Fragment>
          }
        />
        <Route
          path="/roznamcha"
          element={
            <Fragment>
              <Roznamcha />
            </Fragment>
          }
        />
        <Route
          path="/drugReports"
          element={
            <Fragment>
              <DrugReports />
            </Fragment>
          }
        />
        <Route
          path="/drugReportPrint"
          element={
            <Fragment>
              <DrugReportPrint />
            </Fragment>
          }
        />
        <Route
          path="/printDrug"
          element={
            <Fragment>
              <PrintDrug />
            </Fragment>
          }
        />
        <Route
          path="/printFactor"
          element={
            <Fragment>
              <PrintFactor />
            </Fragment>
          }
        />
        <Route
          path="/factorReportPrint"
          element={
            <Fragment>
              <FactorReportPrint />
            </Fragment>
          }
        />
        <Route
          path="/printCustomer"
          element={
            <Fragment>
              <PrintCustomer />
            </Fragment>
          }
        />
        <Route
          path="/printStack"
          element={
            <Fragment>
              <PrintStack />
            </Fragment>
          }
        />
        <Route
          path="/printChecks"
          element={
            <Fragment>
              <PrintChecks />
            </Fragment>
          }
        />
        <Route
          path="/checkReportPrint"
          element={
            <Fragment>
              <CheckReportPrint />
            </Fragment>
          }
        />
        <Route
          path="/printCheck"
          element={
            <Fragment>
              <PrintCheck />
            </Fragment>
          }
        />
        <Route
          path="/printRoznamcha"
          element={
            <Fragment>
              <PrintRoznamcha />
            </Fragment>
          }
        />
        <Route
          path="/printUser"
          element={
            <Fragment>
              <PrintUser />
            </Fragment>
          }
        />
        <Route
          path="/aboutUs"
          element={
            <Fragment>
              <AboutUs />
            </Fragment>
          }
        />
        <Route
          path="/factorReports"
          element={
            <Fragment>
              <FactorReports />
            </Fragment>
          }
        />
        <Route
          path="/checkReports"
          element={
            <Fragment>
              <CheckReport />
            </Fragment>
          }
        />
        <Route
          path="/customerReports"
          element={
            <Fragment>
              <CustomerReports />
            </Fragment>
          }
        />
        <Route
          path="/customerReportPrint"
          element={
            <Fragment>
              <CustomerReportPrint />
            </Fragment>
          }
        />
        <Route
          path="/employee"
          element={
            <Fragment>
              <Eployee />
            </Fragment>
          }
        />
        <Route
          path="/remittance"
          element={
            <Fragment>
              <Remittance />
            </Fragment>
          }
        />
        <Route
          path="/printEmployee"
          element={
            <Fragment>
              <PrintEmployee />
            </Fragment>
          }
        />
        <Route
          path="/printRemittance"
          element={
            <Fragment>
              <PrintRemittance />
            </Fragment>
          }
        />
        <Route
          path="/factorList"
          element={
            <Fragment>
              <FactorList />
            </Fragment>
          }
        />
        <Route
          path="/selectedDrug"
          element={
            <Fragment>
              <SelectedDrug />
            </Fragment>
          }
        />
        <Route
          path="/selectedDrugPrint"
          element={
            <Fragment>
              <SelectedDrugPrint />
            </Fragment>
          }
        />
        <Route
          path="/selectedEmployee"
          element={
            <Fragment>
              <SelectedEmployee />
            </Fragment>
          }
        />

        <Route
          path="/selectedEmployeePrint"
          element={
            <Fragment>
              <SelectedEmployeePrint />
            </Fragment>
          }
        />
        <Route
          path="/expenses"
          element={
            <Fragment>
              <Expenses />
            </Fragment>
          }
        />
        <Route
          path="/printExpenses"
          element={
            <Fragment>
              <PrintExpenses />
            </Fragment>
          }
        />
        <Route
          path="/selectedCustomer"
          element={
            <Fragment>
              <SelectedCustomer />
            </Fragment>
          }
        />
        <Route
          path="/customerSelectedPrint"
          element={
            <Fragment>
              <CustomerSelectedPrint />
            </Fragment>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
