import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRightLong} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS } from "../../GraphQL/Query";
import { Button, Dropdown, Input,Table,Menu, Tag } from "antd";
import {
  CaretDownOutlined,
  PrinterOutlined,
  SelectOutlined
} from "@ant-design/icons";
export function SelectedDrug() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [drugs, setDrugs] = useState([]);
  const [searching, setSearching] = useState("");
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [page, setPage] = useState(1);
  const receivedData = location.state?.drugId || {};
  if (location.pathname == "/selectedDrug") {
    location.pathname = "دارو انتخاب شده";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadDrugDetails();
  }, [referesh]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نوعیت",
      dataIndex: "drugType",
      key: "drugType",
      render: (drugType, drug) => drug?.items[0]?.drug?.drugType?.title,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "name",
      key: "name",
      render: (name, drug) => drug?.items[0]?.drug?.name,
      align: "center"
    },
    {
      title: "کمپنی",
      dataIndex: "company",
      key: "company",
      render: (company, drug) => drug?.items[0]?.drug?.company,
      align: "center"
    },
    {
      title: "تعداد",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, drug) => (
        <Tag bordered={false}
        style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >{drug?.items[0]?.quantity}</Tag>
      ),
      align: "center"
    },
    {
      title: "قیمت",
      dataIndex: "price",
      key: "price",
      render: (price, drug) => (
        <Tag bordered={false} 
          style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >{drug?.items[0]?.price}</Tag>
      ),
      align: "center"
    },
    {
      title: "کل",
      dataIndex: "total",
      key: "total",
      render: (total, drug) => (
        <Tag bordered={false} 
          style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >{drug?.items[0]?.total}</Tag>
      ),
      align: "center"
    },
    {
      title: "مشتری",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName, drug) => drug?.customer?.fullName,
      filteredValue: [searching],
      onFilter: (value, drug) => {
        return String(drug.customer.fullName)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) 
          ||
          String(drug.factorType).includes(value)
          ||
          String(drug.paymentType).startsWith(value)
          ;
      },
      align: "center"
    },
    {
      title: "فاکتور",
      dataIndex: "factorType",
      key: "factorType",
      render: (factorType, drug) =>
      <Tag
      bordered={false}
      color={drug?.factorType == "Sell" ? "success" : "error"}
    >
      {drug?.factorType == "Buy" ? "خرید" : "فروش"}
    </Tag>
        ,
        align: "center"
    },
    {
      title: "سند",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType, drug) =>
        <Tag
          bordered={false}
          color={drug?.paymentType == "Cash" ? "success" : "error"}
        >
          {drug?.paymentType == "Cash" ? "نقده" : "نسیه"}
        </Tag>,
        align: "center"
    },
    {
      title: "شماره",
      dataIndex: "factorNumber",
      key: "factorNumber",
      render: (factorNumber, drug) => (
        <Tag bordered={false}
        style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >
          {drug?.buyFactorNumber || drug?.sellFactorNumber}
        </Tag>
      ),
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "date",
      key: "date",
      render: (date, drug) => drug?.date,
      align: "center"
    },
  ];
  async function loadDrugDetails() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: DRUGDETAILS,
        variables: {
          id: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setDrugs([...res.data.getDrugDetails]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  const filterTypes = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("No_Cash");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          نسیه
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Cash");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          نقده
        </Button>
      ),
    },
  ];
  const factorTypes = (
    <Menu>
      {filterTypes.map((filterType) => (
        <Menu.Item key={filterType.key}>{filterType.label}</Menu.Item>
      ))}
    </Menu>
  );
  const filterPayments = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          همه
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          paddingBlock="0"
          onClick={() => {
            setSearching("Buy");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          خرید
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          block="true"
          icon={<SelectOutlined />}
          onClick={() => {
            setSearching("Sell");
          }}
          style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}
        >
          فروش
        </Button>
      ),
    },
  ];
  const paymentTypes = (
    <Menu>
      {filterPayments.map((filterPayment) => (
        <Menu.Item key={filterPayment.key}>{filterPayment.label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        <div className="file-container">
          <div className="file-header">
            <FontAwesomeIcon icon={faRightLong} style={{ fontSize: 17,cursor:"pointer" }}  onClick={() => navigate(-1)}/>
            <Input.Search
              placeholder="جستجوی مشتری"
              allowClear
              size="large"
              onSearch={(value) =>{
                setSearching(value);
              }}
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            <Dropdown
              overlay={factorTypes}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                انتخاب سند
              </Button>
            </Dropdown>
            <Dropdown
              overlay={paymentTypes}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className="btn"
            >
              <Button type="primary" size="large" icon={<CaretDownOutlined />}>
                انتخاب پرداختی
              </Button>
            </Dropdown>
            <Button type="primary" size="large" icon={<PrinterOutlined />} className="btn" onClick={() =>
                navigate("/selectedDrugPrint", {
                  state: { selectedDrug: receivedData },
                })
              }>
                 چاپ
            </Button>
          </div>
          <div className="file-content">
            <Table
              dataSource={drugs}
              columns={columns}
              loading={isLoader}
              locale={{emptyText:(<img src="emptyData.png" alt="empty" width={200}/>)}}
              pagination={{
                pageSize: 7,
                showSizeChanger:false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
