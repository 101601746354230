import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { STACKS } from "../../GraphQL/Query";
import { ADDSTACK, DELETESTACK, UPDATESTACK } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import { Button, Input, Table, Space, Modal, Popconfirm } from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
export function Stacks() {
  const navigate = useNavigate();
  const client = useApolloClient();
  const location = useLocation();
  const [referesh, setReferesh] = useState();
  const [filteredStack, setFilteredStack] = useState();
  const [searching, setSearching] = useState("");
  const [stacks, setStacks] = useState([]);
  const [showDark, setShowDark] = useState(false);
  const [newStack, setNewStack] = useState(false);
  const [editStack, setEditStack] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/stacks") {
    location.pathname = "گدام ها";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadStacks();
  }, [referesh]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "name",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.name).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      },
      key: "name",
      align: "center"
    },
    {
      title: "نوعیت",
      dataIndex: "type",
      key: "type",
      align: "center"
    },
    {
      title: "آدرس",
      dataIndex: "address",
      key: "address",
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, stack) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EditOutlined onClick={() => handleEditStack(stack?._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeletStack(stack?._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        )
      },
      align: "center"
    },
  ];
  const loadStacks = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: STACKS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setStacks([...res.data.getStacks]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  function goToPrintStack() {
    navigate("/printStack");
  }
  async function addNewStack(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDSTACK,
        variables: {
          name: e.target.stackName.value,
          type: e.target.stackType.value,
          address: e.target.stackAddress.value,
        },
      });
      if (res) {
        setNewStack(!newStack);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeletStack(stackId) {
    try {
      const res = await client.mutate({
        mutation: DELETESTACK,
        variables: {
          id: stackId,
        },
      });
      if (
        res.data.deleteStack.message == "Stack has been deleted successfully"
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      if (
        res.data.deleteStack.message ==
        "please delete related drugs before delete Stack"
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "warning",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditStack(stackId) {
    setShowDark(!showDark);
    const filteredRows = stacks.filter((item) => item._id == stackId);
    setFilteredStack(filteredRows[0]);
    setEditStack(true);
  }
  async function handleUpdateStack(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: UPDATESTACK,
        variables: {
          stackId: filteredStack._id,
          name: e.target.stackName.value,
          type: e.target.stackType.value,
          address: e.target.stackAddress.value,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setEditStack(!editStack);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی گدام"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewStack(!newStack);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت گدام
              </Button>
            }
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={goToPrintStack}
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {editStack && (
              <Modal
                title="ویرایش گدام"
                open={editStack}
                onCancel={() => {
                  setEditStack(!editStack);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateStack}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام گدام"
                      id="stackName"
                      name="stackName"
                      size="large"
                      defaultValue={filteredStack?.name}
                    />
                    <Input
                      placeholder="نوعیت گدام"
                      id="stackType"
                      name="stackType"
                      size="large"
                      defaultValue={filteredStack?.type}
                    />
                    <Input
                      placeholder="آدرس گدام"
                      id="stackAddress"
                      name="stackAddress"
                      size="large"
                      defaultValue={filteredStack?.address}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش گدام
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {newStack && (
              <Modal
                title="ثبت گدام"
                open={newStack}
                onCancel={() => {
                  setNewStack(!newStack);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewStack}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام گدام"
                      id="stackName"
                      name="stackName"
                      size="large"
                    />
                    <Input
                      placeholder="نوعیت گدام"
                      id="stackType"
                      name="stackType"
                      size="large"
                    />
                    <Input
                      placeholder="آدرس گدام"
                      id="stackAddress"
                      name="stackAddress"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت گدام
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={stacks}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
