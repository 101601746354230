import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { SALARIES } from "../../GraphQL/Query";
import { ADDNEWSALARY, DELETESALARY } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import {
  Button,
  Input,
  Table,
  Space,
  Tag,
  InputNumber,
  Modal,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
export function SelectedEmployee() {
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [salaries, setSalaries] = useState([]);
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [salary, setSalary] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  const receivedData = location.state?.employeeId || {};
  if (location.pathname == "/selectedEmployee") {
    location.pathname = "لیست معاشات";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadEmployeeSalaries();
  }, [referesh]);
  async function loadEmployeeSalaries() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: SALARIES,
        variables: {
          employeeId: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setSalaries([...res.data.getSalaries]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  async function addNewSalary(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDNEWSALARY,
        variables: {
          employeeId: receivedData,
          amount: Number(e.target.amount.value),
          date: e.target.inputDate.value,
          description: e.target.description.value,
        },
      });
      console.log("res", res);
      if (res) {
        setSalary(!salary);
        setShowDark(!showDark);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeleteSalary(employeeId) {
    try {
      const res = await client.mutate({
        mutation: DELETESALARY,
        variables: {
          id: employeeId,
        },
      });
      if (res) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "شماره",
      dataIndex: "salaryNumber",
      render: (salaryNumber, salary) => (
        <Tag bordered={false}
          style={{
            fontSize: 15,
            fontFamily: "B-NAZANIN",
          }}
        >{salary.salaryNumber}</Tag>
      ),
      key: "salaryNumber",
      align: "center"
    },
    {
      title: "نام",
      dataIndex: ["fullName", "employeeId"],
      key: "fullName",
      render: (fullName, salary) => salary.employeeId.fullName,
      align: "center"
    },
    {
      title: "مبلغ",
      dataIndex: "amount",
      key: "amount",
      render: (amount, salary) => <Tag bordered={false}
        style={{
          fontSize: 15,
          fontFamily: "B-NAZANIN",
        }}
      >{salary.amount}</Tag>,
      align: "center"
    },
    {
      title: "تاریخ",
      dataIndex: "date",
      key: "date",
      render: (date, salary) => salary.date,
      align: "center"
    },
    {
      title: "توضیحات",
      dataIndex: "description",
      key: "description",
      render: (description, salary) => salary.description,
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, salary) => {
        return (
          userRole === "Admin" ?
            <Space>
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteSalary(salary._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <FontAwesomeIcon icon={faRightLong} style={{ fontSize: 17, cursor: "pointer" }} onClick={() => navigate(-1)} />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSalary(!salary);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت معاش
              </Button>
            }
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={() =>
                navigate("/selectedEmployeePrint", {
                  state: { employeeId: receivedData },
                })
              }
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {salary && (
              <Modal
                title="ثبت معاش کارمند"
                open={salary}
                onCancel={() => {
                  setSalary(!salary);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewSalary}>
                  <Space direction="vertical">
                    <InputNumber
                      id="amount"
                      name="amount"
                      size="large"
                      placeholder="معاش کارمند"
                      min={0}
                      style={{ width: "100%" }}
                    />
                    <Space className="inputDate-container">
                      <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={MONTHS}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                    </Space>
                    <Input
                      id="description"
                      name="description"
                      size="large"
                      placeholder="توضیحات"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                      >
                        ثبت معاش
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={salaries}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
