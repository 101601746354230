import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function CheckReportPrint() {
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [drugs, setDrugs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.selectedCheckReport || {};
  const [checkReportData, setCheckReportData] = useState(receivedData);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="لیست اسناد" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>شماره سند</th>
                <th>نوعیت</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {checkReportData.map((check, index) => {
                return (
                  <tr id={check.id} key={check.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{check.customer.fullName}</td>
                    <td>{check.checkInNumber || check.checkOutNumber}</td>
                    <td>
                      <span>
                        {check.checkType == "Check_In"
                          ? "سند دریافتی"
                          : "سند پرداختی"}
                      </span>
                    </td>
                    <td>{check.amount}</td>
                    <td>{check.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
