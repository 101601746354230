import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/Reports/Report.css";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS, REPORTCUSTOMER } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import { Alert } from "../StaticComponents/Alert";
import { PROVINCES } from "../../Constant/const";
import {
  Button,
  Dropdown,
  Menu,
  Input,
  Table,
  Space,
  Tag,
  Select,
  Modal,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  AlignRightOutlined,
  CaretDownOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SelectOutlined
} from "@ant-design/icons";
const { Option } = Select;
export function CustomerReports() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [showDark, setShowDark] = useState(false);
  const [reportCustomer, setReportCustomer] = useState(false);
  const [reportCustomers, setReportCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searching, setSearching] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [selectedCustomerId,setSelectedCustomerId] = useState("");;
  const [selectedBalanceStatus,setSelectedBalanceStatus] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    type: "",
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/customerReports") {
    location.pathname = "گزارش مشتریان";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomers();
  });
   const columns = [
     {
       title: "ردیف",
       key: "index",
       render: (value, item, index) => (page - 1) * 7 + index + 1,
       align: "center"
     },
     {
       title: "نام",
       dataIndex: "fullName",
       filteredValue: [searching],
       onFilter: (value, record) => {
         return String(record.fullName)
           .toLocaleLowerCase()
           .includes(value.toLocaleLowerCase());
       },
       key: "name",
       align: "center"
     },
     {
       title: "کمپنی",
       dataIndex: "company",
       key: "company",
       align: "center"
     },
     {
       title: "آدرس",
       dataIndex: "address",
       key: "address",
       align: "center"
     },
     {
       title: "موبایل",
       dataIndex: "phoneNumber",
       key: "phoneNumber",
       align: "center"
     },
     {
       title: "کتگوری",
       dataIndex: "category",
       key: "category",
       align: "center"
     },
     {
       title: "ولایت",
       dataIndex: "city",
       key: "city",
       align: "center"
     },
     {
       title: "الباقی",
       dataIndex: "balance",
       key: "balance",
       render: (balance) => (
         <Tag
           bordered={false}
           color={balance > 0 ? "error" : balance === 0 ? "" : "success"}
           style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
         >
           {
             <Space>
               {Math.abs(balance)}
               {""}
               {balance > 0 ? "بستانکار" : balance === 0 ? "تسویه" : "بدهکار"}
             </Space>
           }
         </Tag>
       ),
       align: "center"
     },
   ];
  async function loadCustomers() {
    try {
      const res = await client.query({
        query: CUSTOMERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setCustomers([...res.data.getCustomers]);
    } catch (error) {
      console.log(error);
    }
  }
  const handleReportCustomer = async (e) => {
    e.preventDefault();
    setReportCustomer(!reportCustomer);
    setShowDark(!showDark);
    try {
      const res = await client.query({
        query: REPORTCUSTOMER,
        variables: {
          fullName:
            selectedCustomerId != ""
              ? selectedCustomerId
              : undefined,
          balanceStatus:
            selectedBalanceStatus != ""
              ? selectedBalanceStatus
              : undefined,
          city: selectedProvince != "" ? selectedProvince : undefined,
          category:
            e.target.category.value != "" ? e.target.category.value : undefined,
          address:
            e.target.address.value != "" ? e.target.address.value : undefined,
          startBalance:
            Number(e.target.startBalance.value) != ""
              ? Number(e.target.startBalance.value)
              : undefined,
          endBalance:
            Number(e.target.endBalance.value) != ""
              ? Number(e.target.endBalance.value)
              : undefined,
        },
        fetchPolicy: "no-cache",
      });
      setReportCustomers([...res.data.reportCustomers]);
    } catch (error) {
      console.log(error);
    }
    setSelectedCustomerId()
    setSelectedBalanceStatus()
    setSelectedProvince()
  };
  function gotoPrintCustomer() {
    navigate("/customerReportPrint", {
      state: { selectedCustomerReport: reportCustomers },
    });
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {isLoader && <Loader />}
        {alert.action != "" && (
          <Alert
            statusImg="success"
            borderColor="success"
            type={alert.type}
            action={alert.action}
            alertStatus={alert.alertStatus}
          />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی مشتری"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              onClick={() => {
                setReportCustomer(!reportCustomer);
              }}
              className="btn"
            >
              گزارش مشتری
            </Button>
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={gotoPrintCustomer}
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {reportCustomer && (
              <Modal
                title="گزارش مشتری"
                open={reportCustomer}
                onCancel={() => {
                  setReportCustomer(!reportCustomer);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleReportCustomer}>
                  <Space direction="vertical">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="جستجوی مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="selectedCustomerId"
                      onChange={(value) => {
                        setSelectedCustomerId(value);
                      }}
                      size="large"
                    >
                      {customers.map((customer) => (
                        <Option
                          key={customer._id}
                          value={customer.fullName}
                          style={{ direction: "rtl" }}
                        >
                          {customer.fullName +
                            " " +
                            customer.company +
                            " " +
                            customer.city}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="وضعیت مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="balanceStatus"
                      onChange={(value) => {
                        setSelectedBalanceStatus(value);
                      }}
                      size="large"
                    >
                      <Option
                        key={"Negative"}
                        value={"Negative"}
                        style={{ direction: "rtl" }}
                      >
                        بدهکار
                      </Option>
                      <Option
                        key={"Positive"}
                        value={"Positive"}
                        style={{ direction: "rtl" }}
                      >
                        بستانکار
                      </Option>
                      <Option
                        key={"Zero"}
                        value={"Zero"}
                        style={{ direction: "rtl" }}
                      >
                        تسویه
                      </Option>
                    </Select>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="انتخاب ولایت"
                      style={{ width: "100%", direction: "rtl" }}
                      name="city"
                      onChange={(value) => {
                        setSelectedProvince(value);
                      }}
                      size="large"
                    >
                      {PROVINCES.map((province, index) => (
                        <Option
                          key={index}
                          value={province}
                          style={{ direction: "rtl" }}
                        >
                          {province}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      id="category"
                      name="category"
                      size="large"
                      placeholder="کتگوری مشتری"
                    />
                    <Space direction="horizontal">
                    <InputNumber
                      id="startBalance"
                      name="startBalance"
                      size="large"
                      placeholder="مبلغ مشتری"
                      min={1}
                      className="inputRep"
                    />
                    <span>تا</span>
                    <InputNumber
                      id="endBalance"
                      name="endBalance"
                      size="large"
                      placeholder="مبلغ مشتری"
                      min={1}
                      className="inputRep"
                    />
                    </Space>
                    <Input
                      placeholder="آدرس مشتری"
                      id="address"
                      name="address"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        گزارش مشتری
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={reportCustomers}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger:false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
