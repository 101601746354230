import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PROVINCES } from "../../Constant/const";
import { Alert } from "../StaticComponents/Alert";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS } from "../../GraphQL/Query";
import {
  ADDCUSTOMERS,
  DELETECUSTOMER,
  UPDATECUSTOMER,
} from "../../GraphQL/Mutation";
import { Button, Input, Table, Space, Tag, Select, Popconfirm } from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
const { Option } = Select;
export function Customers() {
  const navigate = useNavigate();
  const client = useApolloClient();
  const location = useLocation();
  const [filteredCustomer, setFilteredCustomer] = useState();
  const [searching, setSearching] = useState("");
  const [customers, setCustomers] = useState([]);
  const [showDark, setShowDark] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/customers") {
    location.pathname = "مشتریان";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomers();
  }, [referesh]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "fullName",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.fullName)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      },
      key: "name",
      align: "center"
    },
    {
      title: "کمپنی",
      dataIndex: "company",
      key: "company",
      align: "center"
    },
    {
      title: "آدرس",
      dataIndex: "address",
      key: "address",
      align: "center"
    },
    {
      title: "موبایل",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center"
    },
    {
      title: "کتگوری",
      dataIndex: "category",
      key: "category",
      align: "center"
    },
    {
      title: "ولایت",
      dataIndex: "city",
      key: "city",
      align: "center"
    },
    {
      title: "الباقی",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => (
        <Tag
          bordered={false}
          color={balance > 0 ? "error" : balance === 0 ? "" : "success"}
          style={{
            fontSize: 15,
          }}
        >
          {
            <Space>
              {Math.abs(balance)}
              {""}
              {balance > 0 ? "بستانکار" : balance === 0 ? "تسویه" : "بدهکار"}
            </Space>
          }
        </Tag>
      ),
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, customer) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EllipsisOutlined
                onClick={() =>
                  navigate("/selectedCustomer", {
                    state: { customerId: customer._id },
                  })
                }
              />
              <EditOutlined onClick={() => handleEditCustomer(customer._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteCustomer(customer._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        );
      },
      align: "center"
    },
  ];
  async function loadCustomers() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CUSTOMERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setCustomers([...res.data.getCustomers]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  function goToPrintCustomer() {
    navigate("/printCustomer");
  }
  async function addNewCustomer(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDCUSTOMERS,
        variables: {
          fullName: e.target.customerName.value,
          company:
            e.target.customerCampany.value != ""
              ? e.target.customerCampany.value
              : undefined,
          category:
            e.target.customerCatagory.value != ""
              ? e.target.customerCatagory.value
              : undefined,
          address:
            e.target.customerAddress.value != ""
              ? e.target.customerAddress.value
              : undefined,
          phoneNumber:
            e.target.customerPhone.value != ""
              ? e.target.customerPhone.value
              : undefined,
          city: selectedProvince,
          balance: 0,
        },
      });
      if (res) {
        setNewCustomer(!newCustomer);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeleteCustomer(customerId) {
    try {
      const res = await client.mutate({
        mutation: DELETECUSTOMER,
        variables: {
          id: customerId,
        },
      });
      if (
        res.data.deleteCustomer.message ==
        "customer has been deleted successfully"
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
        console.log("success");
      }
      if (
        res.data.deleteCustomer.message ==
        "please delete related factore befor delete customer "
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "warning",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditCustomer(customerId) {
    setShowDark(!showDark);
    const filteredRows = customers.filter((item) => item._id == customerId);
    setFilteredCustomer(filteredRows[0]);
    setSelectedProvince(filteredRows[0].city)
    setEditCustomer(true);
  }
  async function handleUpdateCustomer(e) {
    e.preventDefault();
    const id = filteredCustomer._id;
    try {
      const res = await client.mutate({
        mutation: UPDATECUSTOMER,
        variables: {
          customerId: id,
          fullName: e.target.customerName.value,
          company:
            e.target.customerCampany.value != ""
              ? e.target.customerCampany.value
              : undefined,
          category:
            e.target.customerCatagory.value != ""
              ? e.target.customerCatagory.value
              : undefined,
          address:
            e.target.customerAddress.value != ""
              ? e.target.customerAddress.value
              : undefined,
          phoneNumber:
            e.target.customerPhone.value != ""
              ? e.target.customerPhone.value
              : undefined,
          city: selectedProvince,
          balance: filteredCustomer.balance,
        },
      });
      if (res) {
        setEditCustomer(!editCustomer);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
        setSelectedProvince("")
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی مشتری"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewCustomer(!newCustomer);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت مشتری
              </Button>
            }
            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={goToPrintCustomer}
              className="btn"
            >
              چاپ مشتریان
            </Button>
          </div>
          <div className="file-content">
            {editCustomer && (
              <Modal
                title="ویرایش مشتری"
                open={editCustomer}
                onCancel={() => {
                  setEditCustomer(!editCustomer);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateCustomer}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام مشتری"
                      id="customerName"
                      name="customerName"
                      size="large"
                      defaultValue={filteredCustomer.fullName}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="ولایت مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="customerProvince"
                      onChange={(value) => {
                        setSelectedProvince(value);
                      }}
                      size="large"
                      defaultValue={filteredCustomer.city}
                    >
                      {PROVINCES.map((province) => (
                        <Option
                          key={province}
                          value={province}
                          style={{ direction: "rtl" }}
                        >
                          {province}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کتگوری مشتری"
                      id="customerCatagory"
                      name="customerCatagory"
                      size="large"
                      defaultValue={filteredCustomer.category}
                    />
                    <Input
                      placeholder="کمپنی مشتری"
                      id="customerCampany"
                      name="customerCampany"
                      size="large"
                      defaultValue={filteredCustomer.company}
                    />
                    <Input
                      placeholder="آدرس مشتری"
                      id="customerAddress"
                      name="customerAddress"
                      size="large"
                      defaultValue={filteredCustomer.address}
                    />
                    <Input
                      placeholder="موبایل مشتری"
                      id="customerPhone"
                      name="customerPhone"
                      size="large"
                      defaultValue={filteredCustomer.phoneNumber}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش مشتری
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {newCustomer && (
              <Modal
                title="ثبت مشتری"
                open={newCustomer}
                onCancel={() => {
                  setNewCustomer(!newCustomer);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewCustomer}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نام مشتری"
                      id="customerName"
                      name="customerName"
                      size="large"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="ولایت مشتری"
                      style={{ width: "100%", direction: "rtl" }}
                      name="customerProvince"
                      onChange={(value) => {
                        setSelectedProvince(value);
                      }}
                      size="large"
                    >
                      {PROVINCES.map((province) => (
                        <Option
                          key={province}
                          value={province}
                          style={{ direction: "rtl" }}
                        >
                          {province}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      placeholder="کتگوری مشتری"
                      id="customerCatagory"
                      name="customerCatagory"
                      size="large"
                    />
                    <Input
                      placeholder="کمپنی مشتری"
                      id="customerCampany"
                      name="customerCampany"
                      size="large"
                    />
                    <Input
                      placeholder="آدرس مشتری"
                      id="customerAddress"
                      name="customerAddress"
                      size="large"
                    />
                    <Input
                      placeholder="موبایل مشتری"
                      id="customerPhone"
                      name="customerPhone"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت مشتری
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={customers}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
