import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REMITTANCE } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
export function PrintRemittance() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [remittances, setRemittances] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    allRemittance();
  }, []);
  const allRemittance = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: REMITTANCE,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setRemittances([...res.data.getRemittances]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <div className="conatiner">
      <div className="file-container">
        {isLoader && <Loader />}
        <PrintHeader type="لیست حواله جات" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>شماره کارت / حواله</th>
                <th>نوعیت حواله</th>
                <th>طرف حساب</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {remittances.map((rem, index) => {
                return (
                  <tr id={rem._id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{rem.number}</td>
                    <td>
                      <span>
                        {rem.type == "Card_to_card" ? "کارت به کارت" : "صرافی"}
                      </span>
                    </td>
                    <td>{rem.customerName}</td>
                    <td>{rem.amount}</td>
                    <td>{rem.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div>
    </div>
  );
}
