import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERDETAILS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function CustomerSelectedPrint() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [customers, setCustomers] = useState([]);
  const [searching, setSearching] = useState("");
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const receivedData = location.state?.customerId || {};
  if (location.pathname == "/selectedCustomer") {
    location.pathname = "مشتری انتخاب شده";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomerDetails();
  }, [referesh]);
  async function loadCustomerDetails() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CUSTOMERDETAILS,
        variables: {
          id: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setCustomers([...res.data.getCustomerDetails]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="file-container">
        <PrintHeader type="بل های مشتری" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>شماره بل</th>
                <th>نوعیت بل</th>
                <th>نوعیت پرداختی</th>
                <th>مبلغ</th>
                <th>بلانس</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                if (
                  customer?.factorType?.includes(searching) ||
                  customer?.paymentType?.startsWith(searching) ||
                  customer?.checkType?.startsWith(searching)
                ) {
                  return (
                    <tr key={index} className="table-row">
                      <td>{index + 1}</td>
                      <td>{customer?.customer?.fullName}</td>
                      <td>
                        {customer?.checkInNumber ||
                          customer?.checkOutNumber ||
                          customer?.buyFactorNumber ||
                          customer?.sellFactorNumber}
                      </td>
                      <td>
                        <span>
                          {customer?.factorType == "Buy"
                            ? "خرید"
                            : customer?.factorType == "Sell"
                              ? "فروش"
                              : customer?.checkType == "Check_In"
                                ? "دریافتی"
                                : customer?.checkType == "Check_Out"
                                  ? "پرداختی"
                                  : ""}
                        </span>
                      </td>
                      <td>
                        <span>
                          {customer?.paymentType == "Cash"
                            ? "نقده"
                            : customer?.paymentType == "No_Cash"
                              ? "نسیه"
                              : ""}
                        </span>
                      </td>
                      <td>{customer?.amount}</td>
                      <td>
                        {Math.abs(customer?.customerBalance)}
                        {" "}
                        {customer?.customerBalance > 0 ? "بستانکار" : customer?.customerBalance === 0 ? "تسویه" : "بدهکار"}
                      </td>
                      <td>{customer?.date}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
