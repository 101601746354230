import { gql } from "@apollo/client";

// users
// addUser
export const ADDUSER = gql`
  mutation addUser(
    $firstName: String!
    $lastName: String!
    $userName: String!
    $password: String!
    $role: RoleEnum!
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      userName: $userName
      password: $password
      role: $role
    ) {
      _id
      firstName
      lastName
      userName
      password
      role
      createdAt
    }
  }
`;
// deleteUser
export const DELETEUSER = gql`
  mutation deleteUser($id: ID) {
    deleteUser(id: $id) {
      message
    }
  }
`;
// updateUser
export const UPDATEUSER = gql`
  mutation (
    $userId: ID!
    $firstName: String
    $lastName: String
    $userName: String
    $password: String
    $role: RoleEnum
  ) {
    editUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      userName: $userName
      password: $password
      role: $role
    ) {
      _id
      firstName
      lastName
      userName
      password
      role
      createdAt
    }
  }
`;
// stacks
// addStack
export const ADDSTACK = gql`
  mutation addStack($name: String!, $type: String!, $address: String!) {
    addStack(name: $name, type: $type, address: $address) {
      _id
      name
      type
      address
      createdAt
    }
  }
`;
// deleteStack
export const DELETESTACK = gql`
  mutation deleteStack($id: ID!) {
    deleteStack(id: $id) {
      message
    }
  }
`;
// updateStack
export const UPDATESTACK = gql`
  mutation updateStack(
    $stackId: ID!
    $name: String
    $type: String
    $address: String
  ) {
    editStack(stackId: $stackId, name: $name, type: $type, address: $address) {
      _id
      name
      type
      address
      createdAt
    }
  }
`;
// remittance
// deleteRemittance
export const DELETEREMITTANCE = gql`
  mutation deleteRemittance($id: ID!) {
    deleteRemittance(id: $id) {
      message
    }
  }
`;
// addRemittance
export const ADDREMITTANCE = gql`
  mutation addRemittance(
    $number: String!
    $type: ReittanceEnum!
    $amount: Float!
    $customerName: String!
    $shopAddress: String!
    $via: String!
    $description: String
    $date: DateTime!
  ) {
    addRemittance(
      number: $number
      type: $type
      amount: $amount
      customerName: $customerName
      shopAddress: $shopAddress
      via: $via
      description: $description
      date: $date
    ) {
      _id
      number
      type
      amount
      customerName
      shopAddress
      via
      description
      date
      createdAt
    }
  }
`;
// editRemittance
export const UPDATEREMITTANCE = gql`
  mutation editRemittance(
    $remittanceId: ID!
    $number: String!
    $type: ReittanceEnum!
    $amount: Float!
    $customerName: String!
    $shopAddress: String!
    $via: String!
    $description: String
    $date: DateTime!
  ) {
    editRemittance(
      remittanceId: $remittanceId
      number: $number
      type: $type
      amount: $amount
      customerName: $customerName
      shopAddress: $shopAddress
      via: $via
      description: $description
      date: $date
    ) {
      _id
      number
      type
      amount
      customerName
      shopAddress
      via
      description
      date
      createdAt
    }
  }
`;
// addEmployee
export const ADDEMPLOYEE = gql`
  mutation addEmployee(
    $fullName: String
    $phoneNumber: String
    $job: String
    $contractDate: String
    $workTime: String
    $salary: Int
  ) {
    addEmployee(
      fullName: $fullName
      phoneNumber: $phoneNumber
      job: $job
      contractDate: $contractDate
      workTime: $workTime
      salary: $salary
    ) {
      _id
      fullName
      phoneNumber
      job
      contractDate
      workTime
      salary
      createdAt
    }
  }
`;
// updateEmployee
export const UPDATEEMPLOYEE = gql`
  mutation editEmployee(
    $employeeId: ID!
    $fullName: String
    $phoneNumber: String
    $job: String
    $contractDate: String
    $workTime: String
    $salary: Int
  ) {
    editEmployee(
      employeeId: $employeeId
      fullName: $fullName
      phoneNumber: $phoneNumber
      job: $job
      contractDate: $contractDate
      workTime: $workTime
      salary: $salary
    ) {
      _id
      fullName
      phoneNumber
      job
      contractDate
      workTime
      salary
      createdAt
    }
  }
`;
// deleteEmployee
export const DELETEEMPLOYEE = gql`
  mutation deleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      message
    }
  }
`;
// addCustomer
export const ADDCUSTOMERS = gql`
  mutation addCustomer(
    $fullName: String
    $phoneNumber: String
    $city: String
    $address: String
    $company: String
    $balance: Float
    $category: String!
  ) {
    addCustomer(
      fullName: $fullName
      phoneNumber: $phoneNumber
      city: $city
      address: $address
      company: $company
      balance: $balance
      category: $category
    ) {
      _id
      fullName
      phoneNumber
      city
      address
      company
      balance
      category
      createdAt
    }
  }
`;
//updateCustomer
export const UPDATECUSTOMER = gql`
  mutation editCustomer(
    $customerId: ID!
    $fullName: String
    $phoneNumber: String
    $city: String
    $address: String
    $company: String
    $balance: Float
    $category: String
  ) {
    editCustomer(
      customerId: $customerId
      fullName: $fullName
      phoneNumber: $phoneNumber
      city: $city
      address: $address
      company: $company
      balance: $balance
      category: $category
    ) {
      _id
      fullName
      phoneNumber
      city
      address
      company
      balance
      category
      createdAt
    }
  }
`;
// deleteCustomer
export const DELETECUSTOMER = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      message
    }
  }
`;
// addDrugType
export const ADDDRUGTYPE = gql`
  mutation addDrugType($title: String!) {
    addDrugType(title: $title) {
      _id
      title
      createdAt
    }
  }
`;
// deleteDrugType
export const DELETEDRUGTYPE = gql`
  mutation deleteDrugType($id: ID!) {
    deleteDrugType(id: $id) {
      message
    }
  }
`;
//updateDrugType
export const UPDATEDRUGTYPE = gql`
  mutation editDrugType($drugTypeId: ID!, $title: String!) {
    editDrugType(drugTypeId: $drugTypeId, title: $title) {
      _id
      title
      createdAt
    }
  }
`;
// deleteDrug
export const DELETEDRUG = gql`
  mutation deleteDrug($id: ID!) {
    deleteDrug(id: $id) {
      message
    }
  }
`;
// addDrug
export const ADDDRUG = gql`
  mutation addDrug(
    $name: String!
    $drugType: ID!
    $company: String!
    $country: String!
    $amount: Float!
    $price: Float!
    $stack: ID!
    $expDate: String!
  ) {
    addDrug(
      name: $name
      drugType: $drugType
      company: $company
      country: $country
      amount: $amount
      price: $price
      stack: $stack
      expDate: $expDate
    ) {
      _id
      name
      drugType {
        _id
        title
        createdAt
      }
      company
      country
      amount
      price
      stack {
        _id
        name
        address
        createdAt
      }
      expDate
      createdAt
    }
  }
`;
// updateDrug
export const UPDATEDRUG = gql`
  mutation editDrug(
    $drugId: ID!
    $name: String!
    $drugType: ID!
    $company: String!
    $country: String!
    $amount: Float!
    $price: Float!
    $stack: ID!
    $expDate: String!
  ) {
    editDrug(
      drugId: $drugId
      name: $name
      drugType: $drugType
      company: $company
      country: $country
      amount: $amount
      price: $price
      stack: $stack
      expDate: $expDate
    ) {
      _id
      name
      drugType {
        _id
        title
        createdAt
      }
      company
      country
      amount
      price
      stack {
        _id
        name
        address
        createdAt
      }
      expDate
      createdAt
    }
  }
`;
// addcheckIn
export const ADDCHECKIN = gql`
  mutation addCheck(
    $checkType: CheckTypeEnum!
    $date: String!
    $amount: Float!
    $description: String
    $customer: ID!
  ) {
    addCheck(
      checkType: $checkType
      date: $date
      amount: $amount
      description: $description
      customer: $customer
    ) {
      _id
    }
  }
`;
// deleteCheck
export const DELETECHECK = gql`
  mutation deleteCheck($id: ID!) {
    deleteCheck(id: $id) {
      message
    }
  }
`;
// updateCheck
export const UPDATECHECK = gql`
  mutation editCheck(
    $checkId: ID!
    $checkType: CheckTypeEnum!
    $date: String!
    $amount: Float!
    $description: String
    $customer: ID!
  ) {
    editCheck(
      checkId: $checkId
      checkType: $checkType
      date: $date
      amount: $amount
      description: $description
      customer: $customer
    ) {
      _id
    }
  }
`;
// login
export const LOGIN = gql`
  mutation login($userName: String, $password: String) {
    login(userName: $userName, password: $password) {
      _id
      firstName
      lastName
      userName
      password
      role
      createdAt
    }
  }
`;
//deleteFactor
export const DELETEFACTOR = gql`
  mutation deleteFactor($id: ID!) {
    deleteFactor(id: $id) {
      message
    }
  }
`;
//addFactor
export const ADDFACTOR = gql`
  mutation addFactor(
    $factorType: FactorTypeEnum!
    $paymentType: PaymentTypeEnum!
    $date: String!
    $amount: Float!
    $description: String
    $customer: ID!
    $items: [FactorItemInput]
    $discount: Float!
  ) {
    addFactor(
      factorType: $factorType
      paymentType: $paymentType
      date: $date
      amount: $amount
      description: $description
      customer: $customer
      items: $items
      discount: $discount
    ) {
      _id
      buyFactorNumber
      sellFactorNumber
      factorType
      paymentType
      date
      amount
      discount
      description
      customer {
        _id
        fullName
        phoneNumber
        city
        address
        company
        balance
        createdAt
      }
    }
  }
`;
// updateFactor
export const UPDATEFACTOR = gql`
  mutation editFactor(
    $factorId: ID
    $paymentType: PaymentTypeEnum
    $date: String
    $description: String
    $customer: ID
    $items: [FactorItemInput]
  ) {
    editFactor(
      factorId: $factorId
      paymentType: $paymentType
      date: $date
      description: $description
      customer: $customer
      items: $items
    ) {
      _id
      buyFactorNumber
      sellFactorNumber
      factorType
      paymentType
      date
      amount
      description
      customer {
        _id
        fullName
        phoneNumber
        city
        address
        company
        balance
        createdAt
      }
    }
  }
`;
// Backup
export const BACKUP = gql`
mutation getBackup($dbName:String!){
  getBackup(dbName:$dbName){
    message
  }
}
`;
export const ADDNEWSALARY = gql`
  mutation addSalary(
    $employeeId: ID!
    $date: DateTime
    $amount: Int!
    $description: String
  ) {
    addSalary(
      employeeId: $employeeId
      date: $date
      amount: $amount
      description: $description
    ) {
      _id
    }
  }
`;
export const DELETESALARY = gql`
  mutation deleteSalary($id: ID) {
    deleteSalary(id: $id) {
      message
    }
  }
`;
export const DELETEEXPENSE = gql`
  mutation deleteConsume($id: ID) {
    deleteConsume(id: $id) {
      message
    }
  }
`;
export const ADDEXPENSE = gql`
  mutation addConsume(
    $name: String
    $date: DateTime
    $amount: Int!
    $description: String
    $userId: ID!
  ) {
    addConsume(
      name: $name
      date: $date
      amount: $amount
      description: $description
      userId: $userId
    ) {
      _id
    }
  }
`;
export const ADDPROFILE = gql`
mutation addProfile($titleOne: String!,
  $titleTwo: String!,
  $owners: [String]!,
  $phones: [String]!,
  $address: String!){
    addProfile(titleOne:$titleOne,titleTwo:$titleTwo,owners:$owners,phones:$phones,address:$address){
      titleOne
      titleTwo
      owners
      phones
      address
    }
  }
`
export const UPDATEPROFILE = gql`
mutation editProfile($titleOne: String,
  $titleTwo: String,
  $owners: [String],
  $phones: [String],
  $address: String){
    editProfile(titleOne:$titleOne,titleTwo:$titleTwo,owners:$owners,phones:$phones,address:$address){
      titleOne
      titleTwo
      owners
      phones
      address
    }
  }
`