import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { USERROLL } from "../../Constant/const";
import { USERS } from "../../GraphQL/Query";
import { ADDUSER, DELETEUSER, UPDATEUSER } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import { Button, Input, Table, Space, Modal, Select, Popconfirm } from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const { Option } = Select;
export function Users() {
  const navigate = useNavigate();
  const client = useApolloClient();
  const location = useLocation();
  const [filteredUser, setFilteredUser] = useState();
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState("");
  const [showDark, setShowDark] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/users") {
    location.pathname = "کاربران";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadUsers();
  }, [referesh]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center"
    },
    {
      title: "نام",
      dataIndex: "firstName",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.firstName).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      },
      render: (firstName, user) => user.firstName,
      key: "firstName",
      align: "center"
    },
    {
      title: "نام کاربری",
      dataIndex: "userName",
      key: "userName",
      render: (userName, user) => user.userName,
      align: "center"
    },
    {
      title: "پسورد",
      dataIndex: ["password", "role"],
      key: "password",
      render: (password, user, role) => {
        if (userRole == "Admin") {
          return user.password
        } else {
          return "🔒🔏🔐🔓"
        }
      },
      align: "center"
    },
    {
      title: "صلاحیت",
      dataIndex: "role",
      key: "role",
      render: (role, user) => (user.role == "Admin" ? "ادمین" : "عادی"),
      align: "center"
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, user) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EditOutlined onClick={() => handleEditUser(user?._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteUser(user?._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Space>
            : null
        )
      },
      align: "center"
    },
  ];
  const loadUsers = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: USERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setUsers([...res.data.getUsers]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  function goToPrintUser() {
    navigate("/printUser");
  }
  async function addNewUser(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDUSER,
        variables: {
          firstName: e.target.firstName.value,
          lastName: e.target.lastName.value,
          password: e.target.userPassword.value,
          userName: e.target.userName.value,
          role: selectedUserRole,
        },
      });
      if (res) {
        setNewUser(!newUser);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function handleDeleteUser(userId) {
    try {
      const res = await client.mutate({
        mutation: DELETEUSER,
        variables: {
          id: userId,
        },
      });
      if (res) {
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditUser(userId) {
    setShowDark(!showDark);
    const filteredRows = users.filter((item) => item._id == userId);
    setFilteredUser(filteredRows[0]);
    setEditUser(true);
  }
  async function handleUpdateUser(e) {
    e.preventDefault();
    const id = filteredUser._id;
    try {
      const res = await client.mutate({
        mutation: UPDATEUSER,
        variables: {
          userId: id,
          firstName: e.target.firstName.value,
          lastName: e.target.lastName.value,
          password: e.target.userPassword.value,
          userName: e.target.userName.value,
          role: selectedUserRole,
        },
      });
      console.log("pp", res);
      if (res) {
        setEditUser(!editUser);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی کاربر"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {userRole == "Admin" && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewUser(!newUser);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت کاربر
              </Button>
            )}

            <Button
              type="primary"
              size="large"
              icon={<PrinterOutlined />}
              onClick={goToPrintUser}
              className="btn"
            >
              چاپ
            </Button>
          </div>
          <div className="file-content">
            {editUser && (
              <Modal
                title="ویرایش کاربر"
                open={editUser}
                onCancel={() => {
                  setEditUser(!editUser);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateUser}>
                  <Space
                    direction="vertical"
                  >
                    <Input
                      id="firstName"
                      name="firstName"
                      size="large"
                      placeholder="نام کاربر"
                      defaultValue={filteredUser?.firstName}
                    />
                    <Input
                      id="lastName"
                      name="lastName"
                      size="large"
                      placeholder="فامیل کاربر"
                      defaultValue={filteredUser?.lastName}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="صلاحیت کاربر"
                      style={{ width: "100%", direction: "rtl" }}
                      name="userRoll"
                      onChange={(value) => {
                        setSelectedUserRole(value);
                      }}
                      size="large"
                      defaultValue={filteredUser?.role}
                    >
                      <Option
                        key={USERROLL.ADMIN}
                        style={{ direction: "rtl" }}
                        value={USERROLL.ADMIN}
                      >
                        ادمین
                      </Option>
                      <Option
                        key={USERROLL.STANDARD}
                        style={{ direction: "rtl" }}
                        value={USERROLL.STANDARD}
                      >
                        استاندارد
                      </Option>
                    </Select>
                    <Input
                      placeholder="نام کاربری "
                      id="userName"
                      name="userName"
                      size="large"
                      defaultValue={filteredUser?.userName}
                    />
                    <Input.Password
                      placeholder="پسورد کاربر"
                      id="userPassword"
                      name="userPassword"
                      size="large"
                      defaultValue={filteredUser?.password}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش کاربر
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {newUser && (
              <Modal
                title="ثبت کاربر جدید"
                open={newUser}
                onCancel={() => {
                  setNewUser(!newUser);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewUser}>
                  <Space
                    direction="vertical"
                  >
                    <Input
                      id="firstName"
                      name="firstName"
                      size="large"
                      placeholder="نام کاربر"
                    />
                    <Input
                      id="lastName"
                      name="lastName"
                      size="large"
                      placeholder="فامیل کاربر"
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="صلاحیت کاربر"
                      style={{ width: "100%", direction: "rtl" }}
                      name="userRoll"
                      onChange={(value) => {
                        setSelectedUserRole(value);
                      }}
                      size="large"
                    >
                      <Option
                        key={USERROLL.ADMIN}
                        style={{ direction: "rtl" }}
                        value={USERROLL.ADMIN}
                      >
                        ادمین
                      </Option>
                      <Option
                        key={USERROLL.STANDARD}
                        style={{ direction: "rtl" }}
                        value={USERROLL.STANDARD}
                      >
                        استاندارد
                      </Option>
                    </Select>
                    <Input
                      placeholder="نام کاربری "
                      id="userName"
                      name="userName"
                      size="large"
                    />
                    <Input.Password
                      placeholder="پسورد کاربر"
                      id="userPassword"
                      name="userPassword"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت کاربر
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={users}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
