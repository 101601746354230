import "./../../Styles/StaticComponents/Alert.css";
export function Alert(props) {
  const action = props.action;
  const alertStatus = props.alertStatus;
  return (
    <>
      {action == "success" && alertStatus == "backup" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#1DC690" }}
        >
          <img src="success.png" alt="alert" />
          <p>با موفقیت بارگیری گردید</p>
        </div>
      )
       : action == "success" && alertStatus == "create" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#1DC690" }}
        >
          <img src="success.png" alt="alert" />
          <p>با موفقیت اضافه گردید</p>
        </div>
      ) : action == "success" && alertStatus == "delete" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#1DC690" }}
        >
          <img src="success.png" alt="alert" />
          <p>با موفقیت حذف گردید</p>
        </div>
      ) : action == "success" && alertStatus == "edit" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#1DC690" }}
        >
          <img src="success.png" alt="alert" />
          <p>با موفقیت ویرایش گردید</p>
        </div>
      ) : action == "warning" && alertStatus == "delete" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#ffb229" }}
        >
          <img src="warning.png" alt="alert" />
          <p style={{ fontSize: 16 }}>ابتدا اسناد صادر شده را حذف نمایید</p>
        </div>
      ) : action == "empty" && alertStatus == "edit" ? (
        <div
          className="alert-container"
          style={{ borderRightColor: "#ffb229" }}
        >
          <img src="warning.png" alt="alert" />
          <p style={{ fontSize: 16 }}>ابتدا فایل را انتخاب نمایید</p>
        </div>
      )
      :
      (
        ""
      )}
    </>
  );
}
