import { useNavigate } from "react-router";
import "../../Styles/StaticComponents/SideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faBuildingColumns,
  faClipboardList,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faLayerGroup,
  faMoneyCheckDollar,
  faPeopleGroup,
  faPills,
  faTablets,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Menu } from "antd";
import {
  HomeFilled,
  FileTextFilled,
  AreaChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
  StockOutlined,
  DotChartOutlined,
  SettingFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
export function SideBar() {
  const navigate = useNavigate();
  return (
    <div className="sideBar-container">
      <Menu
        onClick={({ key }) => {
          navigate(key);
        }}
        defaultSelectedKeys={[window.location.pathname]}
        mode="inline"
        style={{ fontSize: 17, fontWeight: 600 }}
        items={[
          {
            label: "خانه",
            key: ["/home"],
            icon: <HomeFilled style={{ fontSize: 17 }} />,
          },
          {
            label: "نوعیت ها",
            key: "/drugsType",
            icon: <FontAwesomeIcon icon={faPills} style={{ fontSize: 17 }} />,
          },
          {
            label: "داروها",
            key: "/drugs",
            icon: <FontAwesomeIcon icon={faTablets} style={{ fontSize: 17 }} />,
          },
          {
            label: "فاکتورها",
            key: "/factors",
            icon: (
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                style={{ fontSize: 17 }}
              />
            ),
            children: [
              {
                label: "فاکتور خرید",
                key: "/buyFactor",
                icon: <FileTextFilled style={{ fontSize: 17 }} />,
              },
              {
                label: "فاکتور فروش",
                key: "/saleFactor",
                icon: <FileTextFilled style={{ fontSize: 17 }} />,
              },
              {
                label: "لیست فاکتورها",
                key: "/factorList",
                icon: (
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    style={{ fontSize: 17 }}
                  />
                ),
              },
            ],
          },
          {
            label: "مشتریان",
            key: "/customers",
            icon: (
              <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: 17 }} />
            ),
          },
          {
            label: "گدام ها",
            key: "/stacks",
            icon: (
              <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 17 }} />
            ),
          },
          {
            label: "اسناد",
            key: "/checks",
            icon: (
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                style={{ fontSize: 17 }}
              />
            ),
          },
          {
            label: "مصارف",
            key: "/expenses",
            icon: (
              <FontAwesomeIcon
                icon={faHandHoldingDollar}
                style={{ fontSize: 17 }}
              />
            ),
          },
          {
            label: "روزنامچه",
            key: "/roznamcha",
            icon: (
              <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: 17 }} />
            ),
          },
          {
            label: "گزارشات",
            key: "/reports",
            icon: <AreaChartOutlined style={{ fontSize: 17 }} />,
            children: [
              {
                label: "گزارش داروها",
                key: "/drugReports",
                icon: <BarChartOutlined style={{ fontSize: 17 }} />,
              },
              {
                label: "گزارش فاکتورها",
                key: "/factorReports",
                icon: <DotChartOutlined style={{ fontSize: 17 }} />,
              },
              {
                label: "گزارش اسناد",
                key: "/checkReports",
                icon: <LineChartOutlined style={{ fontSize: 17 }} />,
              },
              {
                label: "گزارش مشتریان",
                key: "/customerReports",
                icon: <StockOutlined style={{ fontSize: 17 }} />,
              },
            ],
          },
          {
            label: "کاربران",
            key: "/users",
            icon: <FontAwesomeIcon icon={faUsers} style={{ fontSize: 17 }} />,
          },
          {
            label: "کارمندان",
            key: "/employee",
            icon: <FontAwesomeIcon icon={faUserTie} style={{ fontSize: 17 }} />,
          },
          {
            label: "بانک ها",
            key: "/remittance",
            icon: (
              <FontAwesomeIcon
                icon={faBuildingColumns}
                style={{ fontSize: 17 }}
              />
            ),
          },
          {
            label: "تنظیمات",
            key: "/settings",
            icon: <SettingFilled style={{ fontSize: 17 }} />,
          },
          {
            label: "درباره",
            key: "/aboutUs",
            icon: <ExclamationCircleFilled style={{ fontSize: 17 }} />,
          },
        ]}
      ></Menu>
    </div>
  );
}
