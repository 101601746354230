import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { CUSTOMERS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
export function PrintCustomer() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadCustomers();
  }, []);
  async function loadCustomers() {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CUSTOMERS,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setCustomers([...res.data.getCustomers]);
        setIsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  }
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="print-container">
        <PrintHeader type="لیست مشتریان" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th className="customer-code">ردیف</th>
                <th className="customer-name">نام</th>
                <th className="customer-company">کمپنی</th>
                <th className="customer-address">آدرس</th>
                <th className="customer-province">ولایت</th>
                <th className="customer-accounting">الباقی</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                return (
                  <tr id={customer.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{customer.fullName}</td>
                    <td>{customer.company}</td>
                    <td>{customer.address}</td>
                    <td>{customer.city}</td>
                    <td>
                      {Math.abs(customer?.balance)}
                      <span>
                        {customer?.balance > 0
                          ? "  بستانکار  "
                          : customer?.balance < 0
                          ? "  بدهکار  "
                          : "  تسویه  "}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div>
    </div>
  );
}
