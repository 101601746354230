import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/Checks/CheckPrint.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { CHECK, LASTCHEK } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
import { BACKEND,DOMAIN_NAME } from "../../Constant/const";
export function PrintCheck() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [lastcheck, setLastCheck] = useState({});
  const [profile, setProfile] = useState()
  const receivedData =
    location.state?.checkType || location.state?.selectedCheck;
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const activeProfile = window.localStorage.getItem("profile");
    setProfile(JSON.parse(activeProfile))
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    if (location.state?.checkType) {
      handleLastCheck();
    }
    if (location.state?.selectedCheck) {
      handlePrintCheck();
    }
  }, [referesh]);
  const handleLastCheck = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: LASTCHEK,
        variables: {
          checkType: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      setLastCheck(res.data.getLastCheck);
      setIsLoader(false);
      setReferesh(true);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      setReferesh(false);
    }
  };
  const handlePrintCheck = async () => {
    try {
      setIsLoader(true);
      const res = await client.query({
        query: CHECK,
        variables: {
          id: receivedData,
        },
        fetchPolicy: "no-cache",
      });
      setLastCheck(res.data.getCheck);
      setIsLoader(false);
      setReferesh(true);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      setReferesh(false);
    }
  };
  return (
    <div className="conatiner">
      {isLoader && <Loader />}
      <div className="checkPrint-header">

        <img src={profile?.logo ? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.logo}` : "logo.png"} alt="logo" />
        <span className="checkPrint-count">
          {lastcheck?.checkType == "Check_In" ? "چک دریافتی" : "چک پرداختی"}{" "}
          {profile?.titleOne}
        </span>
        <span className="checkPrint-count">
          شماره چک: {lastcheck?.checkInNumber || lastcheck?.checkOutNumber}
        </span>
        <FontAwesomeIcon icon={faLeftLong} className="printHeader-icon" style={{ fontSize: 17, cursor: "pointer", position: "absolute", left: 15 }} onClick={() => navigate(-1)} />
      </div>
      <div className="checkPrint-content">
        <div className="check-group">
          <span>تاریخ دریافت</span>
          <span>{lastcheck?.date}</span>
        </div>
        <div className="check-group">
          <span>طرف حساب</span>
          <span>{lastcheck?.customer?.fullName}</span>
        </div>
        <div className="check-group">
          <span>مبلغ</span>
          <span>{lastcheck?.amount}</span>
        </div>
        <div className="check-group">
          <span>بابت</span>
          <span>حساب ها</span>
        </div>
        <div className="check-group">
          <span>توضیحات</span>
          <span>{lastcheck?.description}</span>
        </div>
      </div>
      <div className="checkPrint-signature">
        <span>امضا مدیریت</span>
        <span>امضا خزانه دار</span>
        <span>امضا گیرنده</span>
      </div>
      <div className="checkPrint-details">
        {
          profile?.owners[0] !== '' &&
          <span className="owner-name">{profile?.owners[0]} {profile?.phones[0] !== "" && ':'} {profile?.phones[0]}{profile?.phones[1] !== "" && '،'} {profile?.phones[1]}{profile?.phones[2] !== "" && '،'} {profile?.phones[2]}</span>
        }
        {
          profile?.owners[1] !== '' &&
          <span className="owner-name">{profile?.owners[1]} {profile?.phones[3] !== "" && ':'} {profile?.phones[3]}{profile?.phones[4] !== "" && '،'} {profile?.phones[4]}{profile?.phones[5] !== "" && '،'} {profile?.phones[5]}</span>
        }
        <span>{profile?.address}</span>
        <img
          src={profile?.barcode ? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.barcode}` :"customerBarcod.jpeg"}
          alt="barcod"
          className="checkPrint-barcod"
        />
      </div>
      <div className="printFooter">
        سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
      </div>
      <div className="crop-line"></div>
    </div>
  );
}
