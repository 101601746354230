import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../StaticComponents/Alert";
import { useApolloClient } from "@apollo/client";
import { DRUGTYPES } from "../../GraphQL/Query";
import { Button, Input, Modal, Popconfirm, Space, Table } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  ADDDRUGTYPE,
  DELETEDRUGTYPE,
  UPDATEDRUGTYPE,
} from "../../GraphQL/Mutation";
export function DrugsType() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [filteredDrugsTypes, setFilteredDrugsTypes] = useState([]);
  const [drugTypes, setDrugTypes] = useState([]);
  const [searching, setSearching] = useState("");
  const [editDrugType, setEditDrugType] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [addType, setAddType] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  if (location.pathname == "/drugsType") {
    location.pathname = "نوعیت ها";
  }
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    setUserRole(JSON.parse(activeUser)?.login?.role);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadDrugTypes();
  }, [referesh, alert]);
  const columns = [
    {
      title: "ردیف",
      key: "index",
      render: (value, item, index) => (page - 1) * 7 + index + 1,
      align: "center",
    },
    {
      title: "نام",
      dataIndex: "title",
      key: "title",
      filteredValue: [searching],
      onFilter: (value, record) => {
        return String(record.title)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      },
      align: "center",
    },
    {
      title: userRole === "Admin" ? "عملیات" : null,
      dataIndex: "action",
      key: "action",
      render: (record, drugType) => {
        return (
          userRole === "Admin" ?
            <Space className="action-space">
              <EditOutlined onClick={() => handleEditType(drugType._id)} />
              <Popconfirm
                title="آیا مطمین هستید میخواهید حذف نمایید؟"
                onConfirm={() => handleDeleteType(drugType._id)}
                okText="بله"
                cancelText="انصراف"
                placement="topLeft"
              >
                <DeleteOutlined />
              </Popconfirm> 
            </Space>
            : null
        );
      },
      align: "center",
    },
  ];
  async function loadDrugTypes() {
    try {
      const res = await client.query({
        query: DRUGTYPES,
        variables: {},
        fetchPolicy: "no-cache",
      });
      setDrugTypes([...res.data.getDrugTypes]);
      console.log("drugTypes", drugTypes);
    } catch (error) {
      console.log(error);
    }
  }
  async function addNewDrugType(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDDRUGTYPE,
        variables: {
          title: e.target.drugTypeName.value,
        },
      });
      if (res) {
        setAddType(false);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "create",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleDeleteType(drugTypeId) {
    try {
      const res = await client.mutate({
        mutation: DELETEDRUGTYPE,
        variables: {
          id: drugTypeId,
        },
      });
      if (
        res.data.deleteDrugType.message ==
        "drugType has been deleted successfully"
      ) {
        setAlert({
          action: "success",
          alertStatus: "delete",
        });
        setReferesh(!referesh);
      }
      if (
        res.data.deleteDrugType.message ==
        "please delete related drugs before delete drugType"
      ) {
        setReferesh(!referesh);
        setAlert({
          action: "warning",
          alertStatus: "delete",
        });
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (err) {
      console.log(err);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  function handleEditType(drugTypeId) {
    const filteredRows = drugTypes.filter((item) => item._id == drugTypeId);
    setFilteredDrugsTypes(filteredRows[0]);
    setEditDrugType(true);
  }
  async function handleUpdateDrugType(e) {
    e.preventDefault();
    const id = filteredDrugsTypes._id;
    try {
      const res = await client.mutate({
        mutation: UPDATEDRUGTYPE,
        variables: {
          drugTypeId: id,
          title: e.target.drugTypeName.value,
        },
        fetchPolicy: "no-cache",
      });
      if (res) {
        setEditDrugType(!editDrugType);
        setReferesh(!referesh);
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        setShowDark(!showDark);
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        <div className="file-container">
          <div className="file-header">
            <Input.Search
              placeholder="جستجوی نوعیت"
              allowClear
              size="large"
              onChange={(e) => {
                setSearching(e.target.value);
              }}
              style={{
                width: 250,
                direction: "ltr",
                textAlign: "right",
              }}
            />
            {
              userRole === "Admin" &&
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  setAddType(!addType);
                  setShowDark(!showDark);
                }}
                className="btn"
              >
                ثبت نوعیت
              </Button>
            }
          </div>
          <div className="file-content">
            {addType && (
              <Modal
                title="نوعیت دارو"
                open={addType}
                onCancel={() => {
                  setAddType(!addType);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={addNewDrugType}>
                  <Space direction="vertical">
                    <Input
                      placeholder="نوعیت دارو"
                      id="drugTypeName"
                      name="drugTypeName"
                      size="large"
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ثبت نوعیت
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            {editDrugType && (
              <Modal
                title="نوعیت نوعیت"
                open={editDrugType}
                onCancel={() => {
                  setEditDrugType(!editDrugType);
                }}
                footer={null}
                style={{ direction: "rtl" }}
                centered
              >
                <form onSubmit={handleUpdateDrugType}>
                  <Space direction="vertical">
                    <Input
                      placeholder="ویرایش نوعیت"
                      id="drugTypeName"
                      name="drugTypeName"
                      size="large"
                      defaultValue={filteredDrugsTypes.title}
                    />
                    <Space
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<PlusOutlined />}
                        className="btn"
                      >
                        ویرایش نوعیت
                      </Button>
                    </Space>
                  </Space>
                </form>
              </Modal>
            )}
            <Table
              dataSource={drugTypes}
              columns={columns}
              loading={isLoader}
              locale={{
                emptyText: <img src="emptyData.png" alt="empty" width={200} />,
              }}
              pagination={{
                pageSize: 7,
                showSizeChanger: false,
                onChange(current) {
                  setPage(current);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
